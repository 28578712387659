import { GET_JOB_FILES_SUCCESS } from 'actions/lists';

const reducer = (state: {} | null = null, action) => {
  switch (action.type) {
    case GET_JOB_FILES_SUCCESS:
      return action.payload;
  }

  return state;
};

export default reducer;
