import { dismissModal } from 'actions/modals';
import * as Dashboard from 'components/pages/dashboard';
import DonorLookup from 'components/pages/DonorLookup';
import ErrorPage from 'components/pages/Error';
import Landing from 'components/pages/Landing';
import Results from 'components/pages/Results';
import UploadInfo from 'components/pages/UploadInfo';
import Error from 'components/ui/Error';
import Navbar from 'components/ui/Navbar';
import Page from 'components/ui/Page';
import * as React from 'react';
import * as Modal from 'react-modal';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes';

const customStyles = {
  overlay : {
    position              : 'fixed',
    top                   : '0px',
    left                  : '0px',
    right                 : '0px',
    bottom                : '0px',
    backgroundColor       : 'rgba(127, 127, 127, 0.75)',
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxWidth              : '70em',
    border                : '0px',
  },
};

class App extends React.Component<any, any> {

  public render() {
    const { authenticated, isAdmin, dismiss, loading, modal, error } = this.props;
    if (!authenticated || !isAdmin) {
      return <Redirect to={routes.polaris} />;
    } else if (error && !window.location.pathname.includes('/error')) {
      return <Redirect to={routes.error} />;
    } else {
      const isOpen = !!(modal.type);

      return (
        <div className='app'>
          <header>
            <Navbar />
          </header>
          <Page>
            <Switch>
              <Route exact={true} path={routes.home} component={Landing}/>
              <Route exact={true} path={routes.lists} component={Dashboard.AllResults}/>
              <Route exact={true} path={routes.faq} component={Dashboard.Faq}/>
              <Route exact={true} path={routes.account} component={Dashboard.MyAccount}/>
              <Route exact={true} path={routes.usageSummary} component={Dashboard.UsageSummary}/>
              <Route exact={true} path={routes.usageSummaryAlt} component={Dashboard.UsageSummary}/>
              <Route exact={true} path={routes.lookupHistory} component={Dashboard.LookupHistory}/>
              <Route exact={true} path={routes.results} component={Results}/>
              <Route exact={true} path={routes.uploadInfo} component={UploadInfo}/>
              <Route exact={true} path={routes.donorLookup} component={DonorLookup}/>
              <Route exact={true} path={routes.error} component={ErrorPage}/>
            </Switch>
          </Page>
          <Modal
            isOpen={isOpen}
            onRequestClose={dismiss}
            style={customStyles}
          >
            { isOpen && <modal.type {...modal.props} /> }
          </Modal>
          <Error />
        </div>
      );
    }
  }

}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
  isAdmin: state.auth.user && state.auth.user.campaign && !state.auth.user.campaign.id,
  loading: state.loading,
  modal: state.modals,
  error: state.error,
});

export default connect(mapStateToProps, { dismiss: dismissModal })(App);
