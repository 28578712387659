// Bootstrap requires popper.js for dropdowns and tooltips
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faFilter, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import AdminLogin from 'components/AdminLogin';
import App from 'components/App';
import CreateLogin from 'components/CreateLogin';
import Login from 'components/Login';
import RequestReset from 'components/RequestReset';
import Reset from 'components/Reset';
import PolarisRedirect from 'components/PolarisRedirect';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import store, { history } from 'store';
import 'styles/index.scss';

const rootElement = 'root';
Modal.setAppElement('#' + rootElement);

// Add all FA icons that should be bundled
library.add(faCaretDown, faFilter, faSearch, faTimesCircle);

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact={true} path={routes.login.admin} component={AdminLogin}/>
        <Route exact={true} path={routes.polaris} component={PolarisRedirect}/>
        <Route path={routes.home} component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>
), document.getElementById(rootElement));
