import { lookupIcon } from 'images';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from 'routes';

class Landing extends React.Component<any, any> {

  public render() {
    const { firstName, campaignId, hasOneNation } = this.props;
    const uploadUrl = routes.submission.upload.replace(':campaignId', campaignId);
    const userModeClass = campaignId ? '' : ' disabled';

    return (
      <div className='container-fluid'>
        <div className='card mx-auto card-medium mt-sm-4 mb-5'>
          <div className='card-body center px-4 px-lg-5 py-5'>
            <img height={128} className='mb-4' src={lookupIcon}/>
            <h2 className='card-title mb-3'>Welcome, {firstName}.</h2>
            { hasOneNation ? <>
              <p className='card-text text-nowrap mb-4'>
                It looks like your campaign has been upgraded <wbr/>
                to Polaris, the new version of Fundraiser <wbr/>
                with contact managment and call time features!
              </p>
              <a href={POLARIS_HOST} className='btn btn-lg btn-primary text-uppercase d-block col-sm-8 col-md-6 mx-auto'>
                Take Me There
              </a>
            </> : <>
              <p className='card-text text-nowrap mb-4'>
                Let's get started by uploading <wbr/>
                a fundraising/volunteer list <wbr/>
                or looking up an <wbr/>
                individual's <wbr/>donation history.
              </p>
              <Link to={uploadUrl} className={'btn btn-lg btn-primary text-uppercase d-block mb-4 col-sm-8 col-md-6 mx-auto' + userModeClass}>
                Upload a new list
              </Link>
              <Link to={routes.donorLookup} className='btn btn-lg btn-primary text-uppercase d-block col-sm-8 col-md-6 mx-auto'>
                Quick Lookup
              </Link>
            </>}
            <Link to={routes.lists} className='btn btn-lg btn-secondary text-uppercase d-block col-sm-8 col-md-6 mx-auto mt-5'>
              Past Results
            </Link>
          </div>
        </div>
      </div>
    );
  }

  private toggleShowAll = (event) => {
    const checked = event.target.checked;
    this.props.setShowAllListsFilter(checked);
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    campaignId: state.auth.user.campaign.id,
    firstName: state.auth.user.firstName,
    hasOneNation: !!state.auth.user.campaign.oneNationName,
  };
  return props;
};

export default connect(mapStateToProps, {})(Landing);
