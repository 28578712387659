import { dismissModal } from 'actions/modals';
import * as gateway from 'gateway';
import * as React from 'react';
import { connect } from 'react-redux';

const FILE_DESC = {
  summary: {
    title: 'Summary',
    description: 'This is a birds-eye view of your prospect giving history. It contains one row per prospect and shows you summary metrics for the past few cycles.',
  },
  enriched_contacts: {
    title: 'Enriched Contacts',
    description: 'If you choose to enrich any of your prospects with biographical and contact info, any found information such as email, phone, and summarized biography will be output in this file.',
  },
};

class FileDownload extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public componentWillMount() {
    const getToken = async () => {
      const r = await gateway.get('user/token');
      return r.data;
    };
    getToken().then(data => this.setState(data));
  }

  public render() {
    const {
      dismiss,
      files,
    } = this.props;

    const fileItems = files.map((file, i) => {
      const fileUrl = gateway.generateUrl(file.url, { jwt: this.state.token } );
      const desc = FILE_DESC[file.type] || {};
      const { title, description } = desc;

      return (title &&
        <div key={i}>
          <h6>{title}</h6>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              <p className='text-muted small'>{description}</p>
            </div>
            <div className='ml-5'>
              <a href={fileUrl} className='btn btn-sm btn-primary text-white' download={file.type + '.csv'}>
                Download
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className='modal-content modal-download'>
        <div className='modal-header'>
          <h5 className='modal-title' id='modalLabel'>Files available for download</h5>
          <button type='button' className='btn btn-sm btn-default' onClick={dismiss}>&times;</button>
        </div>
        <div className='modal-body'>
          {fileItems}
        </div>
      </div>
    );
  }
}

export default connect(null, {
  dismiss: dismissModal,
})(FileDownload);
