import Base from 'models/base';

class List extends Base {
  public s3Key: string;
  public createdAt: Date;
  public campaignId: string;
  public jobIds: string[];

  public fileName(): string {
    let fileName = '';
    if (this.s3Key) {
      fileName = this.s3Key.split('/').pop() || '';
    }
    return fileName;
  }

  protected fromJsonInternal(data: any) {
    this.s3Key = data.s3key;
    if (data.createdAt) {
      this.createdAt = new Date(data.createdAt);
    }
    this.campaignId = data.campaignId;
    this.jobIds = data.jobIds;
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.s3Key === other.s3Key &&
      this.campaignId === other.campaignId
    );
  }
}

export default List;
