import * as React from 'react';
import {connect} from 'react-redux';


class UploadInfo extends React.Component<any, any> {

  public render() {
    return (
      <div className='container-fluid'>
        <div className='card mx-auto card-medium'>
          <div className='card-body p-5'>
            <div className='center'>
              <h2 className='card-title mb-4'>CSV File Format Instructions</h2>
            </div>
            <p className='card-text mb-4'>
              When uploading a CSV file of potential donors, use the first row to indicate your column names.{' '}
              <a href='/assets/donors_sample.csv'>Click here for a sample file</a>.
            </p>

            <h4>Required columns</h4>
            You must provide either:
            <ul>
              <li><strong>name</strong> &mdash; the full name of a potential donor, or...</li>
              <li><strong>first_name</strong> and <strong>last_name</strong></li>
            </ul>
            And:
            <ul>
              <li><strong>zipcode</strong> &mdash; a 5-digit ZIP code or a ZIP+4, or...</li>
              <li><strong>city</strong> and <strong>state</strong></li>
            </ul>

            <h4>Optional columns</h4>
            If you plan to reupload this enriched list to NGP at anytime in the future, it is strongly recommended that you include:
            <ul>
              <li><em>VANID</em></li>
            </ul>

            To improve match accuracy, you may provide any of the following fields:
            <ul>
              <li><em>phone</em></li>
              <li><em>email</em></li>
              <li><em>street</em> &mdash; the contact's street address (e.g., 123 Main Street)</li>
              <li><em>city</em></li>
              <li><em>employer</em></li>
              <li><em>occupation</em></li>
            </ul>
            <p>The more fields you provide, the better our match accuracy will be.</p>

            To significantly improve donation predictions, you may also provide:
            <ul>
              <li>
                <em>amount</em> &mdash;
                The total amount (in dollars) a donor has contributed to your campaign <strong>for this election cycle only</strong>
              </li>
            </ul>

            You may also provide the following information to help prioritize your donor list:
            <ul>
              <li>
                <em>amount_lifetime</em> &mdash;
                The total amount (in dollars) a donor has contributed to your candidate
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(UploadInfo);
