const rand = (min: number, max: number) => Math.round(Math.random() * (max - min)) + min;

const bindTrailing = (fn: any, ...boundArgs) => (...args) => fn(...args, ...boundArgs);

const copyToClipboard = (s: string) => {
  const el = document.createElement('textarea');
  el.value = s;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);

  const selObj = document.getSelection();
  if (selObj) {
    const selected = selObj.rangeCount > 0 ? selObj.getRangeAt(0) : false;

    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      selObj.removeAllRanges();
      selObj.addRange(selected);
    }
  }
};


const open = (url: string) => {
  window.open(url);
};

const range = (start: number, end: number) => [...Array(end - start).keys()].map(n => n + start);

const refresh = (path?: string) => {
  if (path) {
    window.location.href = path;
  } else {
    window.location.reload(true);
  }
};

const randString = (len: number) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  range(0, len).forEach(_ => text += possible.charAt(Math.floor(Math.random() * possible.length)));
  return text;
};

const readFileAsync = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = reject;

  reader.readAsText(file);
});

const debounce = (fn, ms) => {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
};

export {
  bindTrailing,
  copyToClipboard,
  debounce,
  open,
  rand,
  randString,
  range,
  readFileAsync,
  refresh,
};
