import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lookupDonor, pingLookupService } from 'actions/donors';
import Currency from 'components/ui/Currency';
import DateTime from 'components/ui/DateTime';
import states from 'helpers/states';
import { moneyIcon, targetIcon } from 'images';
import * as queryString from 'query-string';
import * as React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import routes from 'routes';
import { donorLookupStatsSelector, donorLookupTableDataSelector } from 'selectors/donor-lookup';

const DEFAULT_PAGE_SIZE = 100;

class DonorLookupForm extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      city: null,
      state: 'WA',
    };
  }

  public componentDidMount() {
    const { first, last, city, state } = queryString.parse(location.search);
    const defaultState = state || 'WA';
    this.setState({ firstName: first, lastName: last, city, state: defaultState }, () => {
      if (first && last && city && state) {
        this.lookupDonor();
      }
    });
  }

  public render() {
    const stateOptions = Object.keys(states).map((s) => {
      return <option key={s} value={s}>{states[s]}</option>;
    });

    return (
      <div className='donor-lookup-form input-group text-left mb-3'>
        <div className='form-row mb-2'>
          <div className='form-group col-6 col-lg-3'>
            <label htmlFor='first-name' className='mb-0'>
              <small className='text-uppercase text-muted'>First Name</small>
            </label>
            <input
              id='first-name'
              type='text'
              className='form-control form-control-lg'
              placeholder='Elisa'
              defaultValue={this.state.resultsFirstName}
              onChange={this.onChangeFirstName}
              onKeyUp={this.onKeyUp}/>
          </div>
          <div className='form-group col-6 col-lg-3'>
            <label htmlFor='last-name' className='mb-0'>
              <small className='text-uppercase text-muted'>Last Name</small>
            </label>
            <input
              id='last-name'
              type='text'
              className='form-control form-control-lg'
              placeholder='Smith'
              defaultValue={this.state.resultsLastName}
              onChange={this.onChangeLastName}
              onKeyUp={this.onKeyUp}/>
          </div>
          <div className='form-group col-6 col-lg-3'>
            <label htmlFor='city' className='mb-0'>
              <small className='text-uppercase text-muted'>City</small>
            </label>
            <input
              id='city'
              type='text'
              className='form-control form-control-lg'
              placeholder='Seattle'
              defaultValue={this.state.resultsCity}
              onChange={this.onChangeCity}
              onKeyUp={this.onKeyUp}/>
          </div>
          <div className='form-group col-6 col-lg-2'>
            <label htmlFor='state' className='mb-0'>
              <small className='text-uppercase text-muted'>State</small>
            </label>
            <select
              id='state'
              className='form-control form-control-lg'
              value={this.state.state}
              onChange={this.onChangeState}>
              {stateOptions}
            </select>
          </div>
          <div className='col-12 col-lg-auto'>
            <div className='mb-0 d-none d-lg-block'>&nbsp;</div>
            <button className='btn btn-lg btn-primary mb-2 btn-block' type='button' onClick={this.onLookupClick} disabled={this.props.loading}>
              <FontAwesomeIcon icon='search' className='mx-1 d-none d-lg-inline-block'/>
              <span className='d-lg-none'>Lookup</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value.trim() });
  }

  private onChangeLastName = (event) => {
    this.setState({ lastName: event.target.value.trim() });
  }

  private onChangeCity = (event) => {
    this.setState({ city: event.target.value.trim() });
  }

  private onChangeState = (event) => {
    this.setState({ state: event.target.value });
  }

  private onLookupClick = () => {
    this.lookupDonor();
  }

  private onKeyUp = (e) => {
    if (e.keyCode === 13 && !this.props.loading) {
      this.lookupDonor();
    }
  }

  private lookupDonor = () => {
    let { firstName, lastName, city } = this.state;
    const { state } = this.state;

    if (!firstName || !lastName) {
      alert('Please enter both first and last names.');
      return;
    }
    firstName = firstName.trim();
    lastName = lastName.trim();

    if (!city || !state) {
      alert('Please enter both a city and state.');
      return;
    }
    city = city.trim();

    this.props.lookupDonor(firstName, lastName, null, city, state);
    this.setState({
      resultsFirstName: firstName,
      resultsLastName: lastName,
      resultsCity: city,
      resultsState: state,
    });
    this.props.onSearch();
  }
}

const mapStateToFormProps = (state: any) => {
  const props: any = { loading: state.loading };
  return props;
};

const ConnectedDonorLookupForm = connect(mapStateToFormProps, { lookupDonor })(DonorLookupForm);

class DonorLookupResults extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
    };
  }

  public onTabClick(cycle) {
    this.props.onTabChange(cycle);
  }

  public render() {
    const { donorLookupTableData, loading, donorLookupStats, activeTab } = this.props;
    const cycleStats = donorLookupStats.cycles;
    const donor = donorLookupStats.donor;
    const recentCycles = Object.keys(cycleStats).sort().reverse().slice(0, 3);

    const tabs = recentCycles.map((cycle, index) => {
      const active = index === activeTab ? ' active-tab' : '';
      const text = (index === 0) ? `${cycle} CYCLE` : cycle;
      const marginClass = (index === 2) ? '' : ' mr-sm-5';
      return (
        <li key={index} className={'nav-item' + marginClass}>
          <a className={'nav-link px-0 font-weight-bold' + active} onClick={this.onTabClick.bind(this, index)}>
            {text}
          </a>
        </li>
      );
    });
    const tabsContent = recentCycles.map((cycle, index) => {
      const active = index === activeTab ? ' active' : '';
      const stats = cycleStats[cycle];
      return (
        <div key={index} className={'tab-pane' + active} id={cycle}>
          <div className='row'>
            <div className='col-lg mb-3 mb-lg-0 d-flex'>
              <span className='d-table-cell my-auto pr-4'>
                <img className='insight-icon-small' src={moneyIcon}/>
              </span>
              <div>
                Donated <strong><Currency value={stats.total} /></strong> to <strong>{stats.count} campaign{stats.count === 1 ? '' : 's'}</strong>,
                with an average donation amount of <strong><Currency value={stats.average} /></strong>.
              </div>
            </div>
            <div className='col-lg d-flex'>
              <span className='d-table-cell my-auto pr-4'>
                <img className='insight-icon-small' src={targetIcon}/>
              </span>
              <div>
                We predict this donor is able to donate{' '}
                <span className='insight-number-green font-weight-bold'>
                  <Currency value={donor.prediction} />
                </span> more to a campaign like yours.
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        {loading &&
          <p className='text-center'>Loading...</p>
        }

        {!loading && donorLookupTableData && donorLookupTableData.length === 0 &&
          <p className='text-center'>No result found.</p>
        }

        {!loading && donorLookupTableData && donorLookupTableData.length > 0 &&
          <>
            <div className='bg-white border rounded mb-4 px-3 pt-1'>
              <div className='row no-gutters border-bottom'>
                <div className='offset-sm-5 offset-md-4 offset-lg-3 col-xs-7 col-md-8 col-lg-9'>
                  <ul className='nav justify-content-around justify-content-sm-start'>
                    {tabs}
                  </ul>
                </div>
              </div>
              <div className='row no-gutters text-center text-sm-left py-3'>
                <div className='col-12 col-sm-5 col-md-4 col-lg-3'>
                  <h3 className='mr-sm-4'>{donor.firstName} {donor.lastName}</h3>
                </div>
                <div className='tab-content col-12 col-sm-7 col-md-8 col-lg-9'>
                  {tabsContent}
                </div>
              </div>
            </div>

            <ReactTable
              data={donorLookupTableData}
              className='table-fr mb-3 lookup-table'
              columns={this.getColumns()}
              getTrProps={this.getTrProps}
              minRows={0}
              sortable={true}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              showPagination={donorLookupTableData.length > DEFAULT_PAGE_SIZE}
            />

            <p className='small text-center text-info mb-4'>
              Federal law prohibits using contributor information
              that is obtained from FEC reports for the purpose of
              soliciting contributions or for any commercial purpose.
            </p>
          </>
        }
      </>
    );
  }

  public getTrProps = (state, row) => {
    return {className: 'd-flex flex-wrap'};
  }

  public getPartyStyle = (state, row) => {
    let color = 'black';
    switch (row.original.recipientParty) {
      case '(D)':
        color = 'blue';
        break;
      case '(R)':
        color = 'red';
        break;
      case '(G)':
        color = 'green';
        break;
    }
    return { 'style': { borderLeft: `2px solid ${color}` } };
  }

  private getColumns = () => {
    return [
      {
        Header: 'Recipient',
        accessor: 'recipientName',
        headerClassName: 'd-none d-sm-block',
        className: 'd-flex align-items-center text-capitalize px-3 lookup-rec-td',
        getProps: this.getPartyStyle,
        resizable: false,
        maxWidth: 100,
      }, {
        Header: 'Amount',
        accessor: 'amount',
        className: 'd-flex align-items-center justify-content-end px-3 lookup-second-td',
        resizable: false,
        Cell: row => <Currency value={row.value}/>,
      }, {
        Header: 'Date',
        accessor: 'date',
        className: 'd-flex align-items-center justify-content-center px-3',
        resizable: false,
        minWidth: 100,
        Cell: row => <DateTime value={row.value} format='ll'/>,
      }, {
        Header: 'Source',
        accessor: 'source',
        className: 'd-flex align-items-center justify-content-center px-3',
        resizable: false,
        width: 100,
      }, {
        Header: 'Occupation',
        accessor: 'donorOccupation',
        headerClassName: 'd-none d-lg-block',
        className: 'd-none d-lg-flex align-items-center text-capitalize px-3',
        resizable: false,
      }, {
        Header: 'Employer',
        accessor: 'donorEmployer',
        headerClassName: 'd-none d-lg-block',
        className: 'd-none d-lg-flex align-items-center text-capitalize px-3',
        resizable: false,
      }, {
        Header: 'City',
        accessor: 'donorLocation',
        headerClassName: 'd-none d-lg-block',
        className: 'd-none d-lg-flex align-items-center text-capitalize px-3',
        resizable: false,
      }, {
        Header: 'Zip',
        accessor: 'donorZip',
        headerClassName: 'd-none d-lg-block',
        className: 'd-none d-lg-flex align-items-center justify-content-center px-3',
        resizable: false,
        maxWidth: 100,
      },
    ];
  }
}

const mapStateToResultProps = (state: any) => {
  const props: any = {
    loading: state.loading,
    donorLookupTableData: donorLookupTableDataSelector(state),
    donorLookupStats: donorLookupStatsSelector(state),
  };
  return props;
};

const ConnectedDonorLookupResults = connect(mapStateToResultProps, {})(DonorLookupResults);

class DonorLookup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  public componentDidMount() {
    this.props.pingLookupService();
  }

  public render() {
    return (
      <div className='container-fluid'>
        <div className='mx-auto text-center mt-5 mb-4'>
          <h2>LOOK UP A SUPPORTER</h2>
          <p className='text-muted mb-5'>
            Fill in the information below to locate a prospect's donation history.
          </p>

          <ConnectedDonorLookupForm
            onSearch={this.onSearch} />
          <Link to={routes.lookupHistory}>Recent Lookups</Link>
        </div>

        <ConnectedDonorLookupResults
          activeTab={this.state.activeTab}
          onTabChange={this.onTabChange} />
      </div>
    );
  }

  private onSearch = () => {
    this.setState({ activeTab: 0 });
  }

  private onTabChange = (tab) => {
    this.setState({ activeTab: tab });
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    loading: state.loading,
  };
  return props;
};

export default connect(mapStateToProps, { pingLookupService })(DonorLookup);
