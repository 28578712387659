import { createSelector } from 'reselect';

const listsSelector = (state: any) => state.lists;
const jobsSelector = (state: any) => state.jobs;
const jobFilesSelector = (state: any) => state.jobFiles;
const usersSelector = (state: any) => state.users;
const viewSettingsSelector = (state: any) => state.viewSettings;
const authSelector = (state: any) => state.auth;

const jobsByListSelector = createSelector(listsSelector, jobsSelector, (lists, jobs) => {
  const jobsByList = {};
  if (lists && jobs) {
    Object.keys(lists).forEach(listId => {
      const listJobs = Object.keys(jobs).map(key => jobs[key]).filter(job => job.listId === listId);
      jobsByList[listId] = listJobs;
    });
  }
  return jobsByList;
});

const jobFilesByJobSelector = createSelector(jobsSelector, jobFilesSelector, (jobs, jobFiles) => {
  const jobFilesByJob = {};
  if (jobs && jobFiles) {
    Object.keys(jobs).forEach(jobId => {
      const files = Object.keys(jobFiles).map(key => jobFiles[key]).filter(jobFile => jobFile.jobId === jobId);
      jobFilesByJob[jobId] = files;
    });
  }
  return jobFilesByJob;
});

const lastJobByListSelector = createSelector(jobsByListSelector, (jobsByList) => {
  const lastJobByList = {};
  Object.keys(jobsByList).forEach(listId => {
    const listJobs = jobsByList[listId];
    if (listJobs.length) {
      const lastJob = listJobs.reduce((a, b) => a.updatedAt > b.updatedAt ? a : b);
      lastJobByList[listId] = lastJob;
    }
  });
  return lastJobByList;
});

const jobFilesByListSelector = createSelector(jobsByListSelector, jobFilesByJobSelector,
  (jobsByList, jobFilesByJob) => {
    const jobFilesByList = {};
    Object.keys(jobsByList).forEach(listId => {
      const listJobs = jobsByList[listId];
      const listJobFileArrays = listJobs.map(job => jobFilesByJob[job.id]).filter(f => f);
      const files = listJobs ? [].concat(...listJobFileArrays) : [];
      jobFilesByList[listId] = files;
    });
    return jobFilesByList;
  });

const listTableDataSelector = createSelector(listsSelector, lastJobByListSelector, jobsByListSelector, jobFilesByListSelector,
  usersSelector, viewSettingsSelector, authSelector, (lists, lastJobByList, jobsByList, jobFilesByList, users, viewSettings, auth) => {
    let result;

    if (lists) {
      result = Object.keys(lists).map(listId => {
        const list = lists[listId];
        const jobs = jobsByList[listId];
        const lastJob = lastJobByList[listId];
        let files = jobFilesByList[listId];

        // TODO: this is a hack and we need to rethink about how to properly handle virtual list files
        const hasEnrichment = jobs ? jobs.some(job => job.runPdl && job.status === 'complete') : false;
        const isOneNation = jobs ? jobs.length && jobs.every(job => job.oneNation) : false;

        // Remove job enrichment output files and replace them with a single list enrichment virtual file
        if (files && files.length) {
          files = files.filter(f => f.type !== 'enriched_contacts');

          if (hasEnrichment) {
            files.push({
              type: 'enriched_contacts',
              url: `list/${listId}/file/enriched_contacts`,
            });
          }
        }

        // Get the user's full name
        const user = users && lastJob && users[lastJob.userId] ? users[lastJob.userId].fullName() : null;

        return {
          id: listId,
          filename: list.fileName(),
          createdAt: list.createdAt,
          lastJobId: lastJob ? lastJob.id : null,
          lastJobStatus: lastJob ? lastJob.displayStatus() : null,
          lastJobUpdatedAt: lastJob ? lastJob.updatedAt : null,
          user,
          userId: lastJob ? lastJob.userId : null,
          files,
          hasEnrichment,
          isOneNation,
        };
      });

      // Hide these jobs from this frontend.
      result = result.filter(list => !list.isOneNation);

      // Filter out lists from other campaign users if necessary
      const showAllLists: boolean = viewSettings && viewSettings.showAllLists;
      if (!showAllLists && auth) {
        result = result.filter(list => list.userId === String(auth.user.id));
      }

      result.sort((a, b) => b.createdAt - a.createdAt);
    }

    return result;
  });

export {
  jobFilesByListSelector,
  listTableDataSelector,
};
