import { DISMISS_MODAL, OPEN_MODAL } from 'actions/modals';

interface IState {
  type?: string;
  props?: any;
}

const reducer = (state: IState = {}, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      if (!action.modal.type) {
        return state;
      }

      return action.modal;
    case DISMISS_MODAL:
      return {};
  }

  return state;
};

export default reducer;
