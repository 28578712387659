import { GET_CAMPAIGN_STATS_SUCCESS } from 'actions/campaigns';
import CampaignStats from 'models/campaign-stats';

const reducer = (state: CampaignStats | null = null, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_STATS_SUCCESS:
      return action.payload;
  }

  return state;
};

export default reducer;
