import { logout } from 'actions/auth';
import { ERROR_CLEAR } from 'actions/error';
import { LOADING_FALSE } from 'actions/loader';
import axios from 'axios';
import * as local from 'helpers/local';
import { Nullable } from 'helpers/types';
import User from 'models/user';
import store from 'store';

const { dispatch } = store;

axios.interceptors.response.use((response) => response, (error) => {
  if (401 === error.response.status) {
    // @ts-ignore
    dispatch(logout());
    dispatch({ type: ERROR_CLEAR });
    dispatch({ type: LOADING_FALSE });
  }
  return Promise.reject(error);
});

const generateUrl = (path: string, query?: any) => {
  const url = `${BACKEND_HOST}/${path}`;
  if (query) {
    return `${url}?${ Object.keys(query).map(k => k + '=' + query[k])}`;
  } else {
    return url;
  }
};

const jwt = () => {
  const user: Nullable<User> = local.get(local.TOKEN);
  if (user && user.token) {
    return { jwt: user.token };
  } else {
    return { };
  }
};

const token = () => {
  const user: Nullable<User> = local.get(local.TOKEN);
  if (user && user.token) {
    return user.token;
  }
};

const get = (path: string, params: any = {}, ignoreAuth: boolean = false, skipCache: boolean = false) => {
  const headers = {};
  if (!ignoreAuth) {
    headers['Authorization'] = 'Bearer ' + token();
  }
  if (skipCache) {
    headers['Cache-Control'] = 'no-cache';
  }
  const baseUrl = generateUrl(path);
  const getParams = Object.assign({}, params);
  return axios.get(baseUrl, { params: getParams, headers });
};

const post = (path: string, data: any = null, ignoreAuth: boolean = false) => {
  const headers = ignoreAuth ? {} : { 'Authorization': 'Bearer ' + token() };
  const baseUrl = generateUrl(path);
  return axios.post(baseUrl, data, { headers });
};


const patch = (path: string, data: any = null, ignoreAuth: boolean = false) => {
  const headers = ignoreAuth ? {} : { 'Authorization': 'Bearer ' + token() };
  const baseUrl = generateUrl(path);
  return axios.patch(baseUrl, data, { headers });
};

export {
  generateUrl,
  jwt,
  get,
  post,
  patch,
};
