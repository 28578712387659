import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { push } from 'connected-react-router';
import { post } from 'gateway';
import { remove, set, TOKEN } from 'helpers/local';
import User from 'models/user';
import routes from 'routes';

const AUTHENTICATED = 'auth.true';
const UNAUTHENTICATED = 'auth.false';
const RESET_EMAIL_SENT = 'auth.resetEmailSent.success';

const createLogin = (invitationId: string, token: string, email: string, password: string) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    await post('user/register', {
      invitation_id: invitationId,
      token,
      email,
      password,
    }, true);
    dispatch(push(routes.login.index));
  } catch(e) {
    dispatch(setError('We were not able to create the account. This is likely because the invitation is expired.', true));
  }

  dispatch({ type: LOADING_FALSE });
};

const login = (email: string, password: string) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    const r = await post('user/login', {
      email,
      password,
    }, true);

    const user = (new User()).fromJson(r.data);
    set(TOKEN, user);
    dispatch({ type: AUTHENTICATED, payload: user });
  } catch(e) {
    if (e.response && e.response.status === 403) {
      dispatch(setError('It looks like this account has been deactivated.', true));
    } else {
      dispatch(setError("Oops! We don't recognize that email/password combo. Try again:"));
    }
    dispatch({ type: UNAUTHENTICATED });
  }

  dispatch({ type: LOADING_FALSE });
};

const googleLogin = (googleResponse, invitationId?, invitationToken?) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    const body = {
      token: googleResponse.getAuthResponse().id_token,
      invitationId,
      invitationToken,
    };
    const r = await post('user/google_login', body, true);

    const user = (new User()).fromJson(r.data);
    set(TOKEN, user);
    dispatch({ type: AUTHENTICATED, payload: user });

  } catch(e) {
    dispatch(setError('Unable to authenticate.'));
    dispatch({ type: UNAUTHENTICATED });
  }

  dispatch({ type: LOADING_FALSE });
};

const adminLogin = (message) => async (dispatch) => {
  const user = (new User()).fromJson(message);
  set(TOKEN, user);
  dispatch({ type: AUTHENTICATED, payload: user });
};

const requestReset = (email: string) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    await post('user/create_reset', {
      email,
    }, true);

    dispatch({ type: RESET_EMAIL_SENT, payload: true });
  } catch(e) {
    dispatch(setError('Oops! Something went wrong. Try again!'));
  }

  dispatch({ type: LOADING_FALSE });
};

const reset = (password: string, token: string) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    await post('user/reset', {
      password,
      token,
    }, true);
  } catch(e) {
    dispatch(setError('Oops! We were not able to set your new password. ' +
        'This is likely because the link is expired.', true));
  }

  dispatch({ type: LOADING_FALSE });
};

const logout = () => (dispatch) => {
  remove(TOKEN);
  dispatch({ type: UNAUTHENTICATED });
};

export {
  AUTHENTICATED,
  UNAUTHENTICATED,
  RESET_EMAIL_SENT,
  adminLogin,
  createLogin,
  googleLogin,
  login,
  requestReset,
  reset,
  logout,
};
