import { quickstartGuideThumbnail } from 'images';
import * as React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

export class Faq extends React.Component<any, any> {
  public render() {
    return (
      <div className='container-fluid'>
        <div className='card mx-auto card-medium mb-3'>
          <div className='card-body p-5'>
            <h5 className='text-uppercase mb-4'>Support and FAQ</h5>
            <h5 className='faq-question mb-4'>✪ How do I get started?</h5>
            <p>
              Check out the Fundraiser Quickstart guide:
            </p>
            <a href='/assets/Fundraiser_Quickstart_guide-June.pdf' target='_blank'>
              <img className='mb-4 img-thumbnail' alt='Quickstart Guide' width='300' src={quickstartGuideThumbnail} />
            </a>

            <p className='mb-4'>
              More questions? Don’t be a stranger! Email us at <a href='mailto:fundraising-support@techforcampaigns.org'>fundraising-support@techforcampaigns.org</a>.
            </p>

            <h5 className='faq-question mb-4'>✪ What can I use this tool for?</h5>
            <div className='mb-4'>
              You can use this tool anytime you want to learn more about your prospects. Some ideas:
              <ul>
                <li>Enrich your entire list of supporters in NGP to understand more about them, look up fresh contact information, and find their giving history.</li>
                <li>Enrich a list of ActBlue donors to see who can give more or who you can convert to a recurring donor.</li>
                <li>Enrich a list of registrants or attendees before or after an event to learn more about them and prioritize candidate time.</li>
              </ul>
            </div>

            <h5 className='faq-question mb-4'>✪ Where does the data in Fundraiser come from, and how up-to-date is it?</h5>
            <p className='mb-4'>
              Fundraiser pulls data from both the FEC and *most* state databases, for a collection of over 100 million individual records.
              This data is refreshed on a weekly basis to ensure it’s as up-to-date as possible.
            </p>

            <h5 className='faq-question mb-4'>✪ What data is required to look up giving history?</h5>
            <p>
              You can provide a supporter’s <strong>name</strong> and <strong>city and state</strong><br/>
              <i>or</i><br/>
              You can provide a supporter’s <strong>name</strong> and <strong>zip code</strong>
            </p>
            <p className='mb-4'>
              If you include <strong>employer</strong>, we are typically able to find 5% more donations for your list, but this is optional.
              Check out the upload instructions <Link to={routes.uploadInfo}>here</Link> for more info.
            </p>

            <h5 className='faq-question mb-4'>✪ I’m having trouble uploading my list of prospects. What’s going on?</h5>
            <ul>
              <li>Double check that you’re following the CSV upload instructions <Link to={routes.uploadInfo}>here</Link>.</li>
              <li>The most common user errors happen when the data in a column doesn’t match the column header.
                For example, if you try to upload a list with an Occupation in the Total Amount column you’ll need to correct the data and reupload the list.</li>
            </ul>

            <h5 className='faq-question mb-4'>✪ I’m not getting much info when I try to enrich my list with contact and biographical information. What can I do?</h5>
            <p>
              The best way to improve the quantity and quality of found biographical and contact information is to include <strong>employer</strong> in your list.
            </p>
            <p className='mb-4'>
              Additionally, <strong>any contact information</strong> you already have for your supporters (even if out of date) such as phone and email, can improve the bio/contact match rate and quality.
            </p>

            <h5 className='faq-question mb-4'>✪ How do I give my team members Fundraiser accounts so they can use the tool themselves?</h5>
            <p className='mb-0'>
              Just email <a href='mailto:fundraising-support@techforcampaigns.org'>fundraising-support@techforcampaigns.org</a> with their full name and email address and our support team will add them to your campaign account.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
