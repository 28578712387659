import { AUTHENTICATED, RESET_EMAIL_SENT, UNAUTHENTICATED } from 'actions/auth';
import { PATCH_USER_SUCCESS } from 'actions/user';
import { get, set, TOKEN } from 'helpers/local';
import { Nullable } from 'helpers/types';
import User from 'models/user';

const user: Nullable<User> = get(TOKEN);

interface IState {

  authenticated: boolean;
  user?: User;
  resetEmailSent?: boolean;

}

const initial: IState = {
  authenticated: !!user && !!user.token,
};

if (user) {
  initial.user = user;
}

const reducer = (state: IState = initial, action): IState => {
  switch (action.type) {
    case AUTHENTICATED:
      return { authenticated: true, user: action.payload };
    case UNAUTHENTICATED:
      return { authenticated: false };
    case RESET_EMAIL_SENT:
      return { authenticated: false, resetEmailSent: true };
    case PATCH_USER_SUCCESS:
      const newUser = new User(initial.user);
      newUser.firstName = action.payload.firstName;
      newUser.lastName = action.payload.lastName;
      newUser.email = action.payload.email;
      set(TOKEN, newUser);
      return { authenticated: true, user: newUser };
  }

  return state;
};

export default reducer;
