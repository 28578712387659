import * as React from 'react';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const Currency = (props: any) => {
  const { value } = props;
  if (value) {
    const formattedValue = currencyFormatter.format(value);
    return (<span>{formattedValue}</span>);
  }
  return null;
};

export default Currency;
