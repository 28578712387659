import * as React from 'react';

class BootstrapField extends React.Component<any, any> {
  public render() {
    const { input, label, type, id, className, meta: { touched, error, warning }, disabled } = this.props;

    const isInvalid = touched && (error || warning);
    let inputClassNames = className;
    if (isInvalid) {
      inputClassNames += ' is-invalid';
    }

    return (<div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <input {...input} id={id} placeholder={label} type={type} className={inputClassNames} disabled={disabled} />
      {touched &&
      (
        (error && <div className='invalid-feedback'>{error}</div>) ||
        (warning && <div className='invalid-feedback'>{warning}</div>)
      )}
    </div>);
  }
}

export default BootstrapField;
