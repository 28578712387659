import { GET_INSIGHTS_SUCCESS } from 'actions/results';

const reducer = (state: {} | null = null, action) => {
  switch (action.type) {
    case GET_INSIGHTS_SUCCESS:
      return action.payload;
  }

  return state;
};

export default reducer;
