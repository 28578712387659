import { updateUser } from 'actions/user';
import BootstrapField from 'components/ui/BootstrapField';
import { maxLength, required } from 'helpers/validations';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import routes from 'routes';

const maxLength32 = maxLength(32);

const UserForm = reduxForm({ form: 'update_user' })((props: any) => {
  const { handleSubmit, loading, updateUser } = props;
  const onSubmit = (values: any) => {
    updateUser(values);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        id='firstName'
        name='firstName'
        className='form-control'
        component={BootstrapField}
        type='text'
        label='First Name'
        validate={[required, maxLength32]} />
      <Field
        id='lastName'
        name='lastName'
        className='form-control'
        component={BootstrapField}
        type='text'
        label='Last Name'
        validate={[required, maxLength32]} />
      <Field
        id='email'
        name='email'
        className='form-control'
        component={BootstrapField}
        type='email'
        label='Email Address'
        disabled={true} />
      <div className='row'>
        <div className='col'>
          <button
            type='submit'
            className='btn btn-primary btn text-uppercase'
            disabled={loading}>Update</button>
        </div>
        <div className='col text-right'>
          <Link className='small' to={routes.login.requestReset}>Reset Password</Link>
        </div>
      </div>
    </form>
  );
});


const mapStateToUserProps = (state: any) => {
  const props: any = {
    initialValues: {
      email: state.auth.user.email,
      firstName: state.auth.user.firstName,
      lastName: state.auth.user.lastName,
    },
  };
  return props;
};

const ConnectedUserForm = connect(mapStateToUserProps, { updateUser })(UserForm);

class MyAccount extends React.Component<any, any> {
  public render() {
    return (
      <div className='container-fluid'>
        <div className='card mx-auto card-medium mb-3'>
          <div className='card-body p-5'>
            <h5 className='text-uppercase mb-4'>My Account</h5>
            <ConnectedUserForm />
          </div>
        </div>
      </div>
    );
  }
}

export default MyAccount;
