import { createSelector } from 'reselect';

enum EnrichmentStatus {
  MATCHED = 'matched',
  PENDING = 'pending',
  UNMATCHED = 'unmatched',
}

const ENRICH_STATUS_TO_CLASS = {
  [EnrichmentStatus.MATCHED]: 'icon-green',
  [EnrichmentStatus.PENDING]: 'icon-blue',
  [EnrichmentStatus.UNMATCHED]: 'icon-grey',
};
const ENRICH_STATUS_TO_LABEL = {
  [EnrichmentStatus.MATCHED]: 'Bio/contact info found',
  [EnrichmentStatus.PENDING]: 'Contact enrichment in progress',
  [EnrichmentStatus.UNMATCHED]: 'No bio or contact info found',
};

const formatList = (items: string[]) => items && items.length > 1 ?
  `${items[0]} (+${items.length - 1})` :
  items[0];

const donorsSelector = (state: any) => state.donors;
const viewSettingsSelector = (state: any) => state.viewSettings;

const displayedRecipientsSelector = createSelector(viewSettingsSelector, (viewSettings) => {
  let displayedRecipients = new Map();
  if (viewSettings && viewSettings.displayedRecipients) {
    displayedRecipients = viewSettings.displayedRecipients;
  }
  return displayedRecipients;
});

const donorTableDataSelector = createSelector(donorsSelector, displayedRecipientsSelector, (donors, displayedRecipients) => {
  if (donors && donors.items) {
    const cycles = donors.meta.cycles;

    return donors.items.map((donor, index) => {

      const stats: any = {};
      cycles.forEach(year => {
        const statsCurrentCycle = donor.stats.cycles.find(cycle => cycle.cycle === year);
        stats[`total${year}`] = statsCurrentCycle.total;
        stats[`mean${year}`] = statsCurrentCycle.meanContribution;
        stats[`count${year}`] = statsCurrentCycle.count || null;
      });

      // Build the list of recipients
      const numShown = displayedRecipients[index] || 0;
      const recipients = (donor.recipients || []).slice(0, numShown);
      const children = recipients.map((recipient) => {
        let name = recipient.campaignName;
        if (recipient.partyName && recipient.partyName !== 'Unknown') {
          const party = `(${recipient.partyName.charAt(0)})`;
          if (!name.includes(party)) {
            name = `${recipient.campaignName} ${party}`;
          }
        }

        const totals: any = {};
        cycles.forEach(year => {
          const currentCycle = recipient.cycles.find(cycle => cycle.cycle === year);
          totals[`total${year}`] = currentCycle ? currentCycle.total : null;
        });

        const source = recipient.source && recipient.source.length <= 3 ? recipient.source : null;

        return Object.assign({}, {
          name,
          source,
          lastContributionDate: recipient.lastContributionDate,
        }, totals);
      });

      // $HACK: add special row at the end to show a "load more" button
      if (donor.recipients && donor.recipients.length > numShown) {
        children.push({ name: 'load-more', parentIndex: index, numShown });
      }

      const hasNoContribution = donor.lastContributionDate === null;
      const hasNoRecentContribution = donor.stats.cycles.every((cycle) => cycle.count === 0);

      // Prepare contact info
      let phone: string | null = null;
      let email: string | null = null;
      let occupation: string | null = null;
      let employer: string | null = null;
      let linkedIn: string | null = null;
      let phoneEnriched: boolean = false;
      let emailEnriched: boolean = false;
      let occupationEnriched: boolean = false;
      let employerEnriched: boolean = false;
      let pdlConfidence: number | null = null;
      if (donor.contactInfo) {
        phone = formatList([donor.contactInfo.phone].concat(donor.contactInfo.pdlPhones).filter(n => n));
        email = formatList([donor.contactInfo.email].concat(donor.contactInfo.pdlEmails).filter(n => n));
        phoneEnriched = donor.contactInfo.pdlPhones && donor.contactInfo.pdlPhones.length > 0;
        emailEnriched = donor.contactInfo.pdlEmails && donor.contactInfo.pdlEmails.length > 0;
        occupation = donor.contactInfo.occupation || donor.contactInfo.pdlOccupation;
        occupationEnriched = donor.contactInfo.pdlOccupation && !donor.contactInfo.occupation;
        employer = donor.contactInfo.employer || donor.contactInfo.pdlEmployer;
        employerEnriched = donor.contactInfo.pdlEmployer && !donor.contactInfo.employer;
        linkedIn = donor.contactInfo.linkedIn;
        pdlConfidence = donor.contactInfo.pdlConfidence && +donor.contactInfo.pdlConfidence;
      }

      return Object.assign({}, {
        id: donor.id,
        name: donor.name,
        enrichmentStatus: donor.enrichmentStatus,
        statusIconClass: ENRICH_STATUS_TO_CLASS[donor.enrichmentStatus],
        statusLabel: ENRICH_STATUS_TO_LABEL[donor.enrichmentStatus],
        email,
        emailEnriched,
        phone,
        phoneEnriched,
        linkedIn,
        occupation,
        occupationEnriched,
        employer,
        employerEnriched,
        totalCampaign: donor.totalCampaign,
        totalCampaignLifetime: donor.totalCampaignLifetime,
        capacityRemaining: donor.capacityRemaining,
        lastContributionDate: donor.lastContributionDate,
        pdlConfidence,
        children,
        hasNoContribution,
        hasNoRecentContribution,
      }, stats);
    });
  }
});

const donorTablePagesSelector = createSelector(donorsSelector, (donors) => {
  if (donors && donors.meta) {
    return Math.ceil(donors.meta.total / donors.meta.limit);
  }
});

const donorTableTotalSelector = createSelector(donorsSelector, (donors) => donors && donors.meta ? donors.meta.total : 0);

// $HACK: -1 is a special value to indicate that the data was not provided by the campaign
// Allows us to hide the related column in the results page
const hasTotalCampaignLifetimeSelector = createSelector(donorsSelector, (donors) => {
  let hasTotalCampaignLifetime = false;
  if (donors && donors.items.length > 0) {
    hasTotalCampaignLifetime = donors.items[0].totalCampaignLifetime !== -1;
  }
  return hasTotalCampaignLifetime;
});

const hasEmployerSelector = createSelector(donorsSelector, (donors) => {
  let hasEmployer = false;
  if (donors && donors.meta) {
    hasEmployer = donors.meta.hasEmployer;
  }
  return hasEmployer;
});

const hasOccupationSelector = createSelector(donorsSelector, (donors) => {
  let hasOccupation = false;
  if (donors && donors.meta) {
    hasOccupation = donors.meta.hasOccupation;
  }
  return hasOccupation;
});

const hasCitySelector = createSelector(donorsSelector, (donors) => {
  let hasCity = false;
  if (donors && donors.meta) {
    hasCity = donors.meta.hasCity;
  }
  return hasCity;
});

const recipientSourcesSelector = createSelector(donorsSelector, (donors) => {
  let recipientSources = [];
  if (donors && donors.meta) {
    recipientSources = donors.meta.recipientSources;
  }
  return recipientSources;
});

const donorStatesSelector = createSelector(donorsSelector, (donors) => {
  let donorStates = [];
  if (donors && donors.meta) {
    donorStates = donors.meta.donorStates;
  }
  return donorStates;
});

const donorOccupationClassesSelector = createSelector(donorsSelector, (donors) => {
  let donorOccupationClasses = [];
  if (donors && donors.meta) {
    donorOccupationClasses = donors.meta.donorOccupationClasses;
  }
  return donorOccupationClasses;
});

export {
  donorTableDataSelector,
  donorTablePagesSelector,
  donorTableTotalSelector,
  hasTotalCampaignLifetimeSelector,
  hasEmployerSelector,
  hasOccupationSelector,
  hasCitySelector,
  displayedRecipientsSelector,
  recipientSourcesSelector,
  donorStatesSelector,
  donorOccupationClassesSelector,
};
