import { LOOKUP_DONOR_SUCCESS, LOOKUP_HISTORY_SUCCESS } from 'actions/donors';

const donorLookupReducer = (state: any | null = null, action) => {
  switch (action.type) {
    case LOOKUP_DONOR_SUCCESS:
      return action.payload;
  }

  return state;
};

const donorLookupHistoryReducer = (state: any | null = null, action) => {
  switch (action.type) {
    case LOOKUP_HISTORY_SUCCESS:
      return action.payload;
  }

  return state;
};

export {
  donorLookupHistoryReducer,
  donorLookupReducer,
};
