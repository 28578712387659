import * as React from 'react';

function Page(props: any) {
  return (
    <main>
      {props.children}
    </main>
  );
}

export default Page;
