import { logout } from 'actions/auth';
import * as $ from 'jquery';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import routes from 'routes';

class Navbar extends React.Component<any, any> {

  private pages = [
    {
      route: routes.account,
      label: 'My Account',
    },
    {
      route: routes.usageSummary,
      label: 'Usage Summary',
    },
    {
      route: routes.faq,
      label: 'Support and FAQ',
    },
  ];

  public onLogoutClick = () => {
    this.props.logout();
  }

  public onToggleClick = () => {
    $('#navbarSupportedContent').collapse('hide');
  }

  public render() {
    const { campaignName, firstName, isAdmin } = this.props;

    const mobileLinks = this.pages.map( (page, k) => {
      return (
        <li key={k} className='nav-item d-lg-none'>
          <NavLink to={page.route} className='nav-link' activeClassName='active'>{page.label}</NavLink>
        </li>
      );
    });
    const desktopLinks = this.pages.map( (page, k) => {
      return <NavLink key={k} to={page.route} className='dropdown-item' activeClassName='active'>{page.label}</NavLink>;
    });

    const isHistory = (match, location) => {
      return location.pathname === routes.lists || location.pathname === routes.lookupHistory;
    };

    const userModeClass = isAdmin ? 'bg-admin-header' : 'bg-header';

    return (
      <nav className={'navbar navbar-expand-lg fixed-top navbar-dark shadow-sm ' + userModeClass}>
        <div className='container-fluid'>
          <Link className='navbar-brand' to={routes.home}>
            <span className='text-uppercase'>Fundraiser</span>
            {campaignName &&
              <span className='navbar-brand d-none d-sm-inline-block'>
                <span className='vertical-line mx-4'>&nbsp;</span>
                <span className=''>{campaignName}</span>
              </span>
            }
          </Link>
          {firstName &&
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon'/>
            </button>
          }
          {firstName &&
            <div className='collapse navbar-collapse' id='navbarSupportedContent' onClick={this.onToggleClick}>
              <ul className='navbar-nav mr-auto'>
                <li className='nav-item'>
                  <NavLink exact={true} className='nav-link' activeClassName='active' to={routes.home}>Home</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink isActive={isHistory} className='nav-link' activeClassName='active' to={routes.lists}>Past Results</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink className='nav-link' activeClassName='active' to={routes.donorLookup}>Quick Lookup</NavLink>
                </li>
                {mobileLinks}
                <li className='nav-item d-lg-none'>
                  <a className='nav-link' href='#' onClick={this.onLogoutClick}>Logout</a>
                </li>
              </ul>
              <ul className='navbar-nav d-none d-lg-block'>
                <li className='nav-item dropdown'>
                  <a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button'
                     data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {firstName}
                  </a>
                  <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                    {desktopLinks}
                    <div className='dropdown-divider'/>
                    <a className='dropdown-item' href='#' onClick={this.onLogoutClick}>Logout</a>
                  </div>
                </li>
              </ul>
            </div>
          }
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state: any) => {
  if (state.auth.authenticated) {
    return {
      campaignName: state.auth.user.campaign.name,
      firstName: state.auth.user.firstName,
      isAdmin: state.auth.user.campaign && !state.auth.user.campaign.id,
    };
  } else {
    return {};
  }
};

export default connect(mapStateToProps, { logout })(Navbar);
