const required = value => (value || typeof value === 'number' ? undefined : 'Required');
const maxLength = max => value => (value && value.length > max ? `Must be at most ${max} characters long` : undefined);
const minLength = min => value => (value && value.length < min ? `Must be at least ${min} characters long` : undefined);
const minLength8 = minLength(8);

export {
  required,
  maxLength,
  minLength8,
};
