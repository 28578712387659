import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { patch } from 'gateway';

const PATCH_USER_SUCCESS = 'user.patch.success';

const updateUser = (data: object) => async (dispatch) => {
  dispatch({ type: LOADING_TRUE });
  try {
    const path = 'user';
    const r = await patch(path, data);
    const payload = r.data;

    dispatch({ type: PATCH_USER_SUCCESS, payload });
    dispatch({ type: LOADING_FALSE });
  } catch(e) {
    dispatch(setError(e.message, true));
  }
  dispatch({ type: LOADING_FALSE });
  return null;
};

export {
  PATCH_USER_SUCCESS,
  updateUser,
};
