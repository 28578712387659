import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { get } from 'gateway';
import Insights from 'models/insights';

const GET_DONOR_SUCCESS = 'donor.get.success';
const GET_DONORS_SUCCESS = 'donors.get.success';
const GET_INSIGHTS_SUCCESS = 'job.insights.get.success';
const SET_DISPLAYED_RECIPIENTS = 'displayed-recipients.set.success';
const CLEAR_DISPLAYED_RECIPIENTS = 'displayed-recipients.clear.success';

const getDonors = (listId: string, limit: number = 10, offset: number = 0,
  sort: string[] = [], cycles: string[] = [], filters: any = {},
  skipCache: boolean = false) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    const path = `list/${listId}/donors`;
    const params = { limit, offset, sort, cycles, filters };
    const r = await get(path, params, undefined, skipCache);
    const payload = {
      items: r.data.donors,
      meta: {
        total: r.data.meta.totalDonors,
        limit,
        offset,
        cycles,
        hasEmployer: r.data.meta.hasEmployer,
        hasOccupation: r.data.meta.hasOccupation,
        hasCity: r.data.meta.hasCity,
        recipientSources: r.data.meta.recipientSources,
        donorOccupationClasses: r.data.meta.donorOccupationClasses || [],
        donorStates: r.data.meta.donorStates || [],
      },
    };

    dispatch({ type: GET_DONORS_SUCCESS, payload });
    dispatch(clearDisplayedRecipients());
    dispatch({ type: LOADING_FALSE });
    return payload;
  } catch (e) {
    dispatch(setError(e.message, true));
  }
  dispatch({ type: LOADING_FALSE });
};

const getDonor = (listId: string, donorId: string, cycles: string[] = [], displayIndex: number) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_TRUE });

    const safeDonorId = encodeURIComponent(donorId);
    const path = `list/${listId}/donor/${safeDonorId}`;
    const params = { cycles };
    const r = await get(path, params);
    const payload = r.data.donors ? r.data.donors[0] : null;

    if (payload) {
      dispatch({ type: GET_DONOR_SUCCESS, payload });
      dispatch(setDisplayedRecipients(displayIndex, 10));
    }
    dispatch({ type: LOADING_FALSE });
    return payload;
  } catch (e) {
    dispatch(setError(e.message, true));
  }
  dispatch({ type: LOADING_FALSE });
};

const getInsights = (listId: string) => async (dispatch) => {
  try {
    const path = `list/${listId}/insights`;
    const r = await get(path);
    const payload = (new Insights()).fromJson(r.data);
    dispatch({ type: GET_INSIGHTS_SUCCESS, payload });
    return payload;
  } catch(e) {
    return null;
  }
};

const setDisplayedRecipients = (index: number, value: number) => ({
  type: SET_DISPLAYED_RECIPIENTS,
  index,
  value,
});

const clearDisplayedRecipients = () => ({
  type: CLEAR_DISPLAYED_RECIPIENTS,
});

export {
  GET_DONOR_SUCCESS,
  GET_DONORS_SUCCESS,
  GET_INSIGHTS_SUCCESS,
  SET_DISPLAYED_RECIPIENTS,
  CLEAR_DISPLAYED_RECIPIENTS,
  getDonor,
  getDonors,
  getInsights,
  setDisplayedRecipients,
};
