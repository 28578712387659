import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from 'reducers/auth';
import campaignStatsReducer from 'reducers/campaign-stats';
import { donorLookupHistoryReducer, donorLookupReducer } from 'reducers/donor-lookup';
import donorsReducer from 'reducers/donors';
import errorReducer from 'reducers/error';
import insightsReducer from 'reducers/insights';
import jobReducer from 'reducers/job';
import jobFilesReducer from 'reducers/job-files';
import jobsReducer from 'reducers/jobs';
import listReducer from 'reducers/list';
import listsReducer from 'reducers/lists';
import loaderReducer from 'reducers/loader';
import modalsReducer from 'reducers/modals';
import uploadTokenReducer from 'reducers/upload-token';
import usersReducer from 'reducers/users';
import viewSettingsReducer from 'reducers/view-settings';


const reducer = (history) => combineReducers({
  auth: authReducer,
  campaignStats: campaignStatsReducer,
  donorLookup: donorLookupReducer,
  donorLookupHistory: donorLookupHistoryReducer,
  donors: donorsReducer,
  error: errorReducer,
  form: formReducer,
  insights: insightsReducer,
  job: jobReducer,
  jobs: jobsReducer,
  jobFiles: jobFilesReducer,
  list: listReducer,
  lists: listsReducer,
  loading: loaderReducer,
  modals: modalsReducer,
  router: connectRouter(history),
  uploadToken: uploadTokenReducer,
  users: usersReducer,
  viewSettings: viewSettingsReducer,
});

export default reducer;
