import * as moment from 'moment';
import * as React from 'react';

const DateTime = (props: any) => {
  const { value, format } = props;
  if (value) {
    const formattedValue = moment(value).format(format);
    return (<span>{formattedValue}</span>);
  }
  return null;
};

export default DateTime;
