import { lookupIcon } from 'images';
import * as React from 'react';
import { Link } from 'react-router-dom';

import Navbar from 'components/ui/Navbar';
import Page from 'components/ui/Page';

class PolarisRedirect extends React.Component<any, any> {
  public render() {
    return (
      <div className='app'>
        <header>
          <Navbar />
        </header>
        <Page>
          <div className='container-fluid'>
            <div className='card mx-auto card-medium mt-sm-4 mb-5'>
              <div className='card-body center px-4 px-lg-5 py-5'>
                <img height={128} className='mb-4' src={lookupIcon}/>
                <p className='card-text text-nowrap mb-4'>
                  Fundraiser is no longer supported, but you <wbr/>
                  can try <strong>Polaris</strong>, the new version of Fundraiser <wbr/>
                  with contact managment and call time features!
                </p>
                <a href={POLARIS_HOST} className='btn btn-lg btn-primary text-uppercase d-block col-sm-8 col-md-6 mx-auto'>
                  Take Me There
                </a>
              </div>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}

export default PolarisRedirect;
