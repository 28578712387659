import { failImg } from 'images';
import * as React from 'react';

const Error = (_: any) => {
  return (
    <div className='container center'>
      <div className='card'>
        <div className='card-body p-5'>
          <img className='mb-4' src={failImg} />
          <h2 className='card-title mb-4'>Oops!</h2>
          <p className='card-text mb-4'>
            Something went wrong.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error;
