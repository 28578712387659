const OPEN_MODAL = 'modals.open';
const DISMISS_MODAL = 'modals.dismiss';

const openModal = (type: string, props?: any) => async (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    modal: {
      type,
      props,
    },
  });
};

const dismissModal = (type?: string) => async (dispatch) => {
  dispatch({ type: DISMISS_MODAL });
};

export {
  OPEN_MODAL,
  DISMISS_MODAL,
  openModal,
  dismissModal,
};
