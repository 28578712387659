import Base from 'models/base';

class JobFile extends Base {
  public url: string;
  public type: string;
  public createdAt: Date;
  public jobId: string;

  protected fromJsonInternal(data: any) {
    this.url = data.url;
    this.type = data.type;
    this.createdAt = new Date(data.createdAt);
    this.jobId = data.jobId;
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.id === other.id
    );
  }
}

export default JobFile;
