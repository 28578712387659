import { CREATE_JOB_SUCCESS } from 'actions/jobs';

const reducer = (state: {} | null = null, action) => {
  switch (action.type) {
    case CREATE_JOB_SUCCESS:
      return action.payload;
  }

  return state;
};

export default reducer;
