import { clearError } from 'actions/error';
import * as React from 'react';
import { connect } from 'react-redux';

class Error extends React.Component<any, any> {

  public componentDidMount() {
    setTimeout(this.props.clearError, 4000);
  }

  public render() {
    const { error } = this.props;
    return (<>
      {error &&
        <div id='error' className='centerXY alert alert-danger' role='alert'>
          {error.message}
        </div>
      }
    </>);
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.error,
  };
};

export default connect(mapStateToProps, { clearError } )(Error);
