import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { post } from 'gateway';
import Job from 'models/job';

const CREATE_JOB_SUCCESS = 'jobs.create.success';

const createJob = (listId) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const r = await post('job', {
      listId,
    });
    const job = (new Job()).fromJson(r.data);
    dispatch({ type: CREATE_JOB_SUCCESS, payload: job });
    dispatch({ type: LOADING_FALSE });
    return job;
  } catch (e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

export {
  CREATE_JOB_SUCCESS,
  createJob,
};
