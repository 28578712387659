import { getCampaignStats, refreshCampaignStats } from 'actions/campaigns';
import DateTime from 'components/ui/DateTime';
import { magicWandImg, refreshIcon, targetIcon } from 'images';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { campaignStatsTableDataSelector, currentMonthStatsDataSelector } from 'selectors/campaign-stats';

class CurrentMonthCard extends React.Component<any, any> {

  public render() {
    const { currentMonthStatsData } = this.props;

    if (!currentMonthStatsData) {
      return null;
    }

    return (
      <div className='card mb-4'>
        <div className='card-body'>
          <h6 className='text-uppercase font-weight-bold mb-4'>Campaign Usage Summary</h6>
          <h6 className='text-uppercase font-weight-bold text-muted mb-3'>This month</h6>
          <div className='row'>
            <div className='col-sm d-flex mb-3'>
              <span className='d-table-cell my-auto pr-4'>
                <img className='insight-icon-small' src={targetIcon}/>
              </span>
              <span className='d-table-cell my-auto pl-0 pr-3 h3 insight-number-big'>
                {currentMonthStatsData.donors}
              </span>
              <span className='d-table-cell my-auto insight-text'>
                unique names
              </span>
            </div>
            <div className='col-sm d-flex mb-3'>
              <span className='d-table-cell my-auto pr-4'>
                <SVG src={magicWandImg} className='icon insight-icon-small icon-baseline h3 icon-blue' />
              </span>
              <span className='d-table-cell my-auto pl-0 pr-3 h3 insight-number-big'>
                {currentMonthStatsData.enriched}
              </span>
              <span className='d-table-cell my-auto'>
                 enriched bio/contact info matches
              </span>
            </div>
          </div>
          <small className='text-muted'>usage resets at the end of each month</small>
        </div>
      </div>
    );
  }
}

class UsageSummary extends React.Component<any, any> {

  private campaignId;

  constructor(props) {
    super(props);
    this.campaignId = this.props.match.params.campaignId || this.props.campaignId;
  }

  public componentDidMount() {
    if (this.campaignId) {
      this.props.getCampaignStats(this.campaignId);
    }
  }

  public refresh = () => {
    if (this.campaignId) {
      this.props.refreshCampaignStats(this.campaignId);
    }
  }

  public render() {
    const {
      campaignStatsTableData,
      currentMonthStatsData,
      lastUpdated,
      loading,
    } = this.props;

    return (
      <div className='container-fluid'>
        <CurrentMonthCard currentMonthStatsData={currentMonthStatsData} />

        {campaignStatsTableData && <>
          <ReactTable
            data={campaignStatsTableData}
            className='table-fr'
            columns={this.getColumns()}
            pageSize={campaignStatsTableData.length}
            showPagination={false}
            loading={loading}
            noDataComponent={ () => null }
          />
          <p className='text-right'><small><em>
            Last Updated: <DateTime value={lastUpdated} format='lll'/>
            <span onClick={this.refresh}>
              <SVG onClick={this.refresh} src={refreshIcon} className='icon icon-baseline mx-2 icon-blue' />
            </span>
          </em></small></p>
        </>}
      </div>
    );
  }

  private getColumns = () => {
    return [
      {
        Header: 'Period',
        accessor: 'period',
        sortable: false,
      }, {
        Header: <><span className='d-none d-sm-inline'>Unique </span>Names</>,
        accessor: 'donors',
        sortable: false,
      }, {
        Header: <><span className='d-none d-sm-inline'>Bio/Contact</span> Info Matches</>,
        accessor: 'enriched',
        sortable: false,
      },
    ];
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    campaignId: state.auth.user.campaign.id,
    campaignStatsTableData: campaignStatsTableDataSelector(state),
    currentMonthStatsData: currentMonthStatsDataSelector(state),
    lastUpdated: state.campaignStats && state.campaignStats.lastUpdated,
    loading: state.loading,
  };
  return props;
};

export default connect(mapStateToProps, {
  getCampaignStats, refreshCampaignStats,
})(UsageSummary);
