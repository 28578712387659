import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { get, post } from 'gateway';
import Job from 'models/job';
import JobFile from 'models/job-file';
import List from 'models/list';
import User from 'models/user';

const CREATE_LIST_SUCCESS = 'lists.create.success';
const GET_LISTS_SUCCESS = 'lists.get.success';
const GET_JOBS_SUCCESS = 'jobs.get.success';
const GET_JOB_FILES_SUCCESS = 'jobs-files.get.success';
const GET_USERS_SUCCESS = 'users.get.success';
const SET_SHOW_ALL_LISTS = 'lists.show.all.set';

interface ISegmentFilters {
  minCapacityRemaining: number;
  maxCapacityRemaining: number;
}

const createList = (campaignId, s3Key) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const r = await post('list', {
      campaignId,
      s3Key,
    });
    const list = (new List()).fromJson(r.data);
    dispatch({ type: CREATE_LIST_SUCCESS, payload: list });
    dispatch({ type: LOADING_FALSE });
    return list;
  } catch(e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

const getLists = (campaignId) => async (dispatch) => {
  dispatch({ type: LOADING_TRUE });
  let r;
  try {
    r = await get(`lists?campaignId=${campaignId}&include=jobs,jobs.files,jobs.user`);
  } catch {
    dispatch({ type: LOADING_FALSE });
    return null;
  }
  try {
    const lists: {[id:string]:List} = {};
    const jobs: {[id:string]:Job} = {};
    const jobFiles: {[id:string]:JobFile} = {};
    const users: {[id:string]:User} = {};
    Object.keys(r.data.lists).map(key => {
      lists[key] = (new List()).fromJson(r.data.lists[key]);
    });
    Object.keys(r.data.jobs).map(key => {
      jobs[key] = (new Job()).fromJson(r.data.jobs[key]);
    });
    Object.keys(r.data['jobs.files']).map(key => {
      jobFiles[key] = (new JobFile()).fromJson(r.data['jobs.files'][key]);
    });
    Object.keys(r.data['jobs.user']).map(key => {
      users[key] = (new User()).fromJson(r.data['jobs.user'][key]);
    });

    dispatch({ type: GET_LISTS_SUCCESS, payload: lists });
    dispatch({ type: GET_JOBS_SUCCESS, payload: jobs });
    dispatch({ type: GET_JOB_FILES_SUCCESS, payload: jobFiles });
    dispatch({ type: GET_USERS_SUCCESS, payload: users });
    dispatch({ type: LOADING_FALSE });
    return lists;
  } catch(e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

const setShowAllListsFilter = (value: boolean) => ({
  type: SET_SHOW_ALL_LISTS,
  value,
});

const createSegment = (listId, ids: string[], filters: ISegmentFilters, fields: string[] = []) => async (dispatch) => {
  try {
    const segment: any = {};
    if (ids && ids.length) {
      segment.ids = ids;
    } else {
      segment.filters = filters;
    }

    const r = await post(`list/${listId}/segment`, {
      segment, fields,
    });

    return r.data;
  } catch(e) {
    dispatch(setError(e.message, true));
  }
};

export {
  CREATE_LIST_SUCCESS,
  GET_LISTS_SUCCESS,
  GET_JOBS_SUCCESS,
  GET_JOB_FILES_SUCCESS,
  GET_USERS_SUCCESS,
  SET_SHOW_ALL_LISTS,
  createList,
  createSegment,
  getLists,
  setShowAllListsFilter,
};
