import { Nullable } from 'helpers/types';

const TOKEN = 'token';

const get = <T> (key: string): Nullable<T> => {
  const raw = localStorage.getItem(key);
  try {
    return !!raw ? JSON.parse(raw) as T : null;
  } catch (e) {
    return null;
  }
};

const set = <T> (key: string, value: T) => {
  if (typeof value !== 'string') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

const remove = (key: string) => {
  localStorage.removeItem(key);
};

export {
  TOKEN,
  get,
  set,
  remove,
};
