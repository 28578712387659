import { adminLogin } from 'actions/auth';
import * as React from 'react';
import { connect } from 'react-redux';

class AdminLogin extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.receiveToken = this.receiveToken.bind(this);
    this.prepareForToken = this.prepareForToken.bind(this);
  }

  public componentDidMount() {
    this.prepareForToken();
    this.requestToken();
  }

  public prepareForToken() {
    window.addEventListener('message', this.receiveToken, false);
  }

  public receiveToken(event) {
    if (event.origin !== `${ADMIN_HOST}` || event.source !== window.opener) {
      return;
    }
    this.props.adminLogin(event.data);
    window.close();
  }

  public requestToken() {
    window.opener.postMessage('ready', `${ADMIN_HOST}`);
  }

  public render() {
    return (
      <div>Trying to log you in...</div>
    );
  }
}

const mapStateToAdminLoginProps = (state: any) => ({
  error: state.error,
});

export default connect(mapStateToAdminLoginProps, {
  adminLogin,
})(AdminLogin);
