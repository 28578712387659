import { ERROR, ERROR_CLEAR } from 'actions/error';

const reducer = (state: string | null = null, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case ERROR_CLEAR:
      return null;
  }

  return state;
};

export default reducer;
