import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSegment } from 'actions/lists';
import { openModal } from 'actions/modals';
import { getDonor, getDonors, getInsights, setDisplayedRecipients } from 'actions/results';
import EnrichConfirmation from 'components/modals/EnrichConfirmation';
import Currency from 'components/ui/Currency';
import states from 'helpers/states';
import { debounce } from 'helpers/utils';
import { enrichStatusImg, magicWandImg, moneyIcon, noHistory, noRecentHistory, targetIcon } from 'images';
import * as $ from 'jquery';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import * as selectors from 'selectors/donors';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const RoundedCurrency = (props: any) => {
  const { value } = props;
  const rounded = value - (value % 10);
  if (rounded) {
    const formattedValue = currencyFormatter.format(rounded);
    return (<span>{formattedValue}</span>);
  }
  return null;
};

const Name = (props: any) => {
  const {
    linkedIn,
    name,
    onLoadMore,
    statusLabel,
    statusIconClass,
    source,
  } = props;

  if (name === 'load-more') {
    return (<a href='#' onClick={onLoadMore}>Load more...</a>);
  }

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <span title={name} data-toggle='tooltip' data-placement='right' className='text-truncate pr-1'>
        {linkedIn ? <a href={linkedIn} target='_blank'>{name}</a> : name}
      </span>
      {statusIconClass &&
      <span title={statusLabel} data-toggle='tooltip' data-placement='right'>
        <SVG src={enrichStatusImg} className={statusIconClass + ' icon icon-baseline mr-1'}/>
      </span>
      }
      {source &&
      <span className='badge badge-light'>{source}</span>
      }
    </div>
  );
};

const getEnrichedCellProps = (state, row) => (field) => {
  const props: any = {};
  if (row && row.row) {
    // Add a light green background to enriched phone/email cells
    props.style = {
      background: row.row[field] === true ? '#f2fee8' : '',
    };
  }
  return props;
};

const getCycles = () => {
  const currentYear = (new Date()).getFullYear();
  const currentCycle = currentYear % 2 === 0 ? currentYear : currentYear + 1;
  return [currentCycle, currentCycle - 2, currentCycle - 4].map(String);
};

const getCycleColumns = (cycle) => {
  const totalAccessor = `total${cycle}`;
  const countAccessor = `count${cycle}`;
  const meanAccessor = `mean${cycle}`;
  return [
    {
      Header: 'Total',
      accessor: totalAccessor,
      className: 'text-right',
      sortable: true,
      Cell: row => <Currency value={row.value}/>,
    }, {
      Header: 'Count',
      accessor: countAccessor,
      width: 60,
      className: 'text-right',
      sortable: true,
    }, {
      Header: 'Mean',
      accessor: meanAccessor,
      className: 'text-right',
      sortable: true,
      Cell: row => <Currency value={row.value}/>,
    },
  ];
};

const InsightsCard = (props: any) => {
  const { firstName, insights } = props;
  return (
    <div className='insight-card card mb-3 d-none d-lg-flex'>
      <div className='mx-4 my-auto'>
        <div className='row'>
          <div className='col-sm d-flex'>
            <span className='d-table-cell my-auto'>
              Hey <b>{firstName}</b>, we've found some interesting info for you. It looks like:
            </span>
          </div>
          {insights.over500 > 0 &&
            <>
              <div className='col-sm d-flex'>
                <span className='d-table-cell my-auto pr-4'>
                  <img className='insight-icon-small' src={targetIcon}/>
                </span>
                <span className='d-table-cell my-auto pr-3 h3 insight-number-big'>{insights.over500}</span>
                <span className='d-table-cell my-auto insight-text'>
                  people could increase their donation by at least <span className='insight-number-green font-weight-bold'>$500</span>.
                </span>
              </div>
              <div className='insight-separator' />
            </>
          }
          <div className='col-sm d-flex'>
            <span className='d-table-cell my-auto pr-4'>
              <img className='insight-icon-small' src={moneyIcon}/>
            </span>
            <span className='d-table-cell my-auto pr-3 h3 insight-number-big'><Currency value={insights.canBeRaised}/></span>
            <span className='d-table-cell my-auto insight-text'>could potentially be raised from donors in this list.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

class ActionsAndFilters extends React.Component<any, any> {
  private readonly sourceSelect;

  constructor(props) {
    super(props);
    this.sourceSelect = React.createRef();
  }

  public componentDidUpdate() {
    // Correct the menu's position in case the button changed.
    $('[data-toggle="dropdown"]').dropdown('update');
  }

  public render() {
    const {
      minFilter,
      maxFilter,
      search,
      employerFilter,
      donorOccupationFilter,
      cityFilter,
      donorStateFilter,
      recipientSourcesFilter,
      selectedDonorCount,
      onEnrichClick,
      onChangeMin,
      onChangeMax,
      onChangeSearch,
      onSearch,
      onSelectSource,
      onRemoveSource,
      onAddAllSources,
      onResetFilters,
      onChangeEmployer,
      onChangeDonorOccupation,
      onChangeCity,
      onChangeDonorState,
      pdlEnabled,
      hasEmployer,
      hasCity,
      recipientSources,
      donorStates,
      donorOccupationClasses,
    } = this.props;

    const onKeyUp = (e) => {
      if (e.keyCode === 13) {
        onSearch();
      }
    };

    const onChangeSource = (e) => {
      onSelectSource(e);
      this.sourceSelect.current.value = '';
    };

    // Ensure arrays
    const sources = recipientSources || [];
    const sourcesFilter = recipientSourcesFilter || [];

    const federalSources = sources.filter(s => s === 'FEC').filter(s => !sourcesFilter.includes(s));
    const stateSources = sources.filter(s => s !== 'FEC').filter(s => !sourcesFilter.includes(s));

    let filterCount = 0;
    if (sourcesFilter.length >0) { filterCount++; }
    if (employerFilter) { filterCount++; }
    if (donorOccupationFilter) { filterCount++; }
    if (cityFilter) { filterCount++; }
    if (donorStateFilter) { filterCount++; }
    if (minFilter || maxFilter) { filterCount++; }

    const buttonClass = filterCount > 0 ? 'btn-primary' : 'btn-outline-primary';

    return (
      <div className='d-flex justify-content-between mb-3'>
        <div>
          <button type='button' className='btn btn-primary mb-1' onClick={onEnrichClick}
                  disabled={selectedDonorCount <= 0 || pdlEnabled !== true}>
            <SVG src={magicWandImg} className='icon icon-baseline icon-white'/> Enrich bio & contact info
          </button>
          <label className='my-1 ml-4'>
            {selectedDonorCount} Donors Selected
          </label>
        </div>

        <div className='d-inline-flex'>
          <div className='form-inline'>
            <label className='my-1 mr-2' htmlFor='min'>
              Contact Lookup
            </label>
            <input type='search' className='form-control mb-1 mr-sm-3' id='search'
                   placeholder='Elisa Smith'
                   value={search}
                   onChange={onChangeSearch}
                   onKeyUp={onKeyUp}/>
          </div>
          <div className='dropdown'>
            <button className={'btn ' + buttonClass} type='button' id='dropdownMenuButton' data-toggle='dropdown'
                    aria-haspopup='true' aria-expanded='false' data-offset='0,10'>
              <FontAwesomeIcon icon='filter' className='mr-1'/>
              Advanced Filters {filterCount > 0 && '(' + filterCount + ')'}
              <FontAwesomeIcon icon='caret-down' className='ml-1'/>
            </button>
            <div className='dropdown-menu dropdown-menu-right dropdown-search-menu' aria-labelledby='dropdownMenuButton'>
              <form className='px-4 py-3'>
                <div className='d-inline-flex form-fields'>
                  <div className='form-group'>
                    <label className='mb-0' htmlFor='recipientSources'>
                      Contributed to campaigns in...
                    </label>
                    <div className={sourcesFilter.length > 0 ? 'mb-0' : 'mb-0 empty-sub-label'}>
                      {sourcesFilter.map((source, index) => {
                        return (
                          <button type='button' key={index} onClick={onRemoveSource.bind(this, source)}
                                  className='border border-secondary rounded-pill px-1 mr-1 mb-0 text-white bg-secondary small d-inline-block'>
                            {source}
                            <FontAwesomeIcon icon='times-circle' className='ml-1'/>
                          </button>
                        );
                      })}
                      &nbsp;
                    </div>
                    <select className='mt-1 form-control' id='recipientSources' onChange={onChangeSource} disabled={!recipientSources.length} defaultValue='' ref={this.sourceSelect}>
                      <option key='none' value='' disabled={true} hidden={true}>Select sources...</option>
                      {federalSources.length > 0 &&
                      <optgroup label='Federal'>
                        {federalSources.map((source, index) => {
                          return (<option key={index} value={source}>{source}/Federal</option>);
                        })}
                      </optgroup>
                      }
                      {stateSources.length > 0 &&
                      <optgroup label='States'>
                        {stateSources.map((source, index) => {
                          return (<option key={index} value={source}>{states[source] || source}</option>);
                        })}
                      </optgroup>
                      }
                    </select>
                    {sources.length > 0 &&
                    <a href='javascript:void(0)' role='button' onClick={onAddAllSources} className='small'>Add all</a>
                    }
                  </div>

                  {(hasEmployer || donorOccupationClasses.length > 0) &&
                  <div className='form-group'>
                    Employment
                    <div className='form-row'>
                    {hasEmployer &&
                      <div className='col'>
                        <label className='mb-0' htmlFor='employer'>
                          <small className='text-uppercase text-muted'>Employer</small>
                        </label>
                        <input type='text' className='mt-1 form-control' id='employer'
                               value={employerFilter}
                               onChange={onChangeEmployer}
                               onKeyUp={onKeyUp}/>
                      </div>
                    }

                    {donorOccupationClasses.length > 0 &&
                      <div className={hasEmployer ? 'col-5' : 'col'}>
                        <label htmlFor='occupation' className='mb-0'>
                          <small className='text-uppercase text-muted'>Occupation Type</small>
                        </label>
                        <select className='mt-1 form-control' id='occupation' onChange={onChangeDonorOccupation} defaultValue='' value={donorOccupationFilter}>
                          <option key='none' value=''>Select</option>
                          {donorOccupationClasses.map((occupation) => {
                            return (<option key={occupation} value={occupation}>{occupation}</option>);
                          })}
                        </select>
                      </div>
                    }
                    </div>
                  </div>
                  }

                  {(hasCity || donorStates.length > 0) &&
                  <div className='form-group'>
                    Donor Location
                    <div className='form-row'>
                    {hasCity &&
                      <div className='col'>
                        <label htmlFor='city' className='mb-0'>
                          <small className='text-uppercase text-muted'>City</small>
                        </label>
                        <input type='text' className='mt-1 form-control' id='city'
                               value={cityFilter}
                               onChange={onChangeCity}
                               onKeyUp={onKeyUp}/>
                      </div>
                    }

                    {donorStates.length > 0 &&
                      <div className={hasCity ? 'col-5' : 'col'}>
                        <label htmlFor='state' className='mb-0'>
                          <small className='text-uppercase text-muted'>State</small>
                        </label>
                        <select className='mt-1 form-control' id='state' onChange={onChangeDonorState} defaultValue='' value={donorStateFilter}>
                          <option key='none' value=''>Select</option>
                          {donorStates.map((state) => {
                            return (<option key={state} value={state}>{states[state] || state}</option>);
                          })}
                        </select>
                      </div>
                    }
                    </div>
                  </div>
                  }

                  <div className='form-group'>
                    <label className='mb-0' htmlFor='min'>
                      Estimated Additional Give
                    </label>
                    <div className='form-row'>
                      <div className='col-4'>
                        <label htmlFor='min' className='mb-0'>
                          <small className='text-uppercase text-muted'>Minimum</small>
                        </label>
                      </div>
                      <div className='col-1'/>
                      <div className='col-4'>
                        <label htmlFor='max' className='mb-0'>
                          <small className='text-uppercase text-muted'>Maximum</small>
                        </label>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-4'>
                        <input type='number' className='mt-1 form-control' id='min'
                               value={minFilter}
                               onChange={onChangeMin}
                               onKeyUp={onKeyUp}/>
                      </div>
                      <div className='col-1 text-center my-auto'>&ndash;</div>
                      <div className='col-4'>
                        <input type='number' className='mt-1 form-control' id='max'
                               value={maxFilter}
                               onChange={onChangeMax}
                               onKeyUp={onKeyUp}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex justify-content-between'>
                  <button type='button' className='btn btn-outline-primary mr-2' onClick={onResetFilters}>Reset Filters</button>
                  <button type='button' className='btn btn-primary' data-toggle='dropdown'>Close</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToActionsAndFiltersProps = (state: any) => {
  return {
    pdlEnabled: state.auth.user.campaign.pdlEnabled,
    hasEmployer: selectors.hasEmployerSelector(state),
    hasCity: selectors.hasCitySelector(state),
    recipientSources: selectors.recipientSourcesSelector(state),
    donorStates: selectors.donorStatesSelector(state),
    donorOccupationClasses: selectors.donorOccupationClassesSelector(state),
  };
};

const ActionsAndFiltersConnected = connect(mapStateToActionsAndFiltersProps, null)(ActionsAndFilters);

class Results extends React.Component<any, any> {

  private refReactTable;
  private readonly debouncedUpdateFilteredState;

  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.debouncedUpdateFilteredState = debounce(this.updateFilteredState, 500);

    this.state = {
      filtered: [], // used by react table
      minFilter: '',
      maxFilter: '',
      employerFilter: '',
      cityFilter: '',
      recipientSourcesFilter: [],
      selected: {},
      expanded: {},
      selectAll: false,
      donorsStale: false,
    };
  }

  public componentDidMount() {
    const listId = this.props.match.params.listId;
    this.props.getInsights(listId);
    this.setupTooltips();
    addEventListener('resize', this.fixAlignment);
  }

  public getSnapshotBeforeUpdate() {
    this.cleanTooltips();
    return null;
  }

  public componentDidUpdate() {
    this.setupTooltips();
    this.fixAlignment();
  }

  public fetchData(state) {
    const listId = this.props.match.params.listId;
    if (listId) {
      // Limit and offset
      const limit = state.pageSize;
      const offset = state.page * limit;

      // Sorting
      let sort = ['-capacityRemaining', '-totalCapacity'];
      if (state.sorted.length) {
        sort = state.sorted.map((p) => `${p.desc ? '-': ''}${p.id}`);
      }

      // Cycles
      const cycles = getCycles();

      // Filters
      const filters = this.toApiFilters(state.filtered);

      const skipCache = this.state.donorsStale;

      this.props.getDonors(listId, limit, offset, sort, cycles, filters, skipCache).then(() => {
        this.setState({ donorsStale: false });
      });
    }
  }

  public toApiFilters(reactTableFilters) {
    let filters: any = {};
    if (reactTableFilters) {
      filters = reactTableFilters.reduce((map, obj) => {
        if (obj.value) {
          map[obj.id] = obj.value;
        }
        return map;
      }, {});
    }

    // Special case: all states selected except FEC
    if (filters.recipientSources && filters.recipientSources.length > 0) {
      filters.recipientSources = this.checkStatesOnly(filters.recipientSources);
      filters.recipientSourceCycles = getCycles();
    }

    return filters;
  }

  public getRightColumns() {
    return [
      {
        Header: 'Last Donation',
        accessor: 'lastContributionDate',
        className: 'text-center',
        sortable: true,
      }, {
        Header: 'Email',
        accessor: 'email',
        className: 'text-center',
        minWidth: 170,
        sortable: false,
        getProps: (state, row) => getEnrichedCellProps(state, row)('emailEnriched'),
      }, {
        Header: 'Phone',
        accessor: 'phone',
        className: 'text-center',
        minWidth: 130,
        sortable: false,
        getProps: (state, row) => getEnrichedCellProps(state, row)('phoneEnriched'),
      },
    ];
  }

  public getLeftColumns(isSubTable: boolean, showTotalCampaignLifetime: boolean, showOccupation: boolean, showEmployer: boolean) {
    return [
      {
        Header: <SVG src={magicWandImg} className='icon icon-baseline mb-1'/>,
        columns: [
          {
            Header: x => {
              return (
                <div className='text-center'>
                  <input
                    type='checkbox'
                    checked={this.state.selectAll === true}
                    ref={input => {
                      if (input) {
                        input.indeterminate = this.state.selectAll === false && this.getSelectedCount() > 0;
                      }
                    }}
                    onChange={() => this.toggleSelectAll()}
                  />
                </div>
              );
            },
            Cell: ({ original }) => {
              return (
                original.pdlConfidence ?
                  <span title='Bio/Contact info confidence score (out of 10) ' data-toggle='tooltip' data-placement='right'>{original.pdlConfidence * 10}</span> :
                  <input
                    type='checkbox'
                    checked={this.state.selected[original.id] === true || (this.state.selectAll === true && original.enrichmentStatus === null)}
                    disabled={this.state.selectAll === true || this.state.donorsStale || original.enrichmentStatus !== null}
                    onChange={() => this.toggleRow(original.id)}
                  />
              );
            },
            className: 'text-center',
            sortable: false,
            width: 35,
            show: !isSubTable,
          },
        ],
      },
      {
        expander: true,
        sortable: false,
        width: 35,
        Expander: this.renderExpander,
        show: !isSubTable,
      },
      {
        Header: 'Donor',
        accessor: 'name',
        className: 'text-capitalize column-name',
        minWidth: 210,
        sortable: false,
        Cell: this.renderName,
      }, {
        Header: 'Occupation',
        accessor: 'occupation',
        minWidth: 130,
        sortable: false,
        show: showOccupation,
        getProps: (state, rowInfo, column) => (
          {
            'style': {
                background: rowInfo && rowInfo.original.occupationEnriched ? '#f2fee8' : '',
            },
            'title': rowInfo && rowInfo.row.occupation,
            'data-toggle': 'tooltip',
            'data-placement': 'right',
          }
        ),
      }, {
        Header: 'Employer',
        accessor: 'employer',
        minWidth: 130,
        sortable: false,
        show: showEmployer,
        getProps: (state, rowInfo, column) => (
          {
            'style': {
                background: rowInfo && rowInfo.original.employerEnriched ? '#f2fee8' : '',
            },
            'title': rowInfo && rowInfo.row.employer,
            'data-toggle': 'tooltip',
            'data-placement': 'right',
          }
        ),
      }, {
        Header: 'Your Campaign',
        headerClassName: 'campaign-th',
        columns: [
          {
            Header: <span>Cycle</span>,
            accessor: 'totalCampaign',
            className: 'text-right',
            minWidth: 100,
            Cell: row => <Currency value={row.value}/>,
          }, {
            Header: <span>Lifetime</span>,
            accessor: 'totalCampaignLifetime',
            className: 'text-right',
            minWidth: 100,
            show: showTotalCampaignLifetime,
            Cell: row => <Currency value={row.value}/>,
          }, {
            Header: <span>Est. Capacity</span>,
            accessor: 'capacityRemaining',
            className: 'text-right',
            minWidth: 100,
            Cell: row => <RoundedCurrency value={row.value}/>,
          },
        ],
      },
    ];
  }

  public getColumns(isSubTable: boolean, hasTotalCampaignLifetime: boolean, hasOccupation: boolean, hasEmployer: boolean) {
    const cycles = getCycles();

    const leftColumns = this.getLeftColumns(isSubTable, hasTotalCampaignLifetime, hasOccupation, hasEmployer);

    let centerColumns: any = [];
    cycles.forEach(year => {
      const cycleColumns = getCycleColumns(year);

      // Group columns by cycle in the main table only
      // The sub-tables (visible when expanding a row) have the same columns but without grouping
      if (isSubTable) {
        centerColumns = centerColumns.concat(cycleColumns);
      } else {
        centerColumns.push({
          Header: year,
          columns: cycleColumns,
        });
      }
    });

    const rightColumns: any = this.getRightColumns();

    return leftColumns.concat(centerColumns).concat(rightColumns);
  }

  public cleanTooltips() {
    const tooltips = $('[data-toggle="tooltip"]');
    tooltips.tooltip('dispose');
  }

  public setupTooltips() {
    const tooltips = $('[data-toggle="tooltip"]');
    tooltips.tooltip();
  }

  public fixAlignment() {
    const mainTbody = document.getElementById('main-rt-tbody');
    if (mainTbody) {
      const scrollWidth = mainTbody.offsetWidth - mainTbody.clientWidth;
      $('.table-fr-results .rt-thead').css({ paddingRight: scrollWidth + 'px' });
    }
  }

  public toggleRow(id) {
    const newSelected = Object.assign({}, this.state.selected);
    if (newSelected[id] === true) {
      delete newSelected[id];
    } else {
      newSelected[id] = true;
    }
    this.setState({
      selected: newSelected,
    });
  }

  public unselectAll() {
    this.setState({
      selected: {},
      selectAll: false,
    });
  }

  public toggleSelectAll() {
    this.setState({
      selected: {},
      selectAll: !this.state.selectAll,
    });
  }

  public getSelectedCount() {
    let selectedCount = 0;
    if (this.state.selectAll === true) {
      selectedCount = this.props.donorTableTotal;
    } else {
      selectedCount = Object.keys(this.state.selected).length;
    }
    return selectedCount;
  }

  public onEnrichClick = () => {
    const count = this.getSelectedCount();
    const listId = this.props.match.params.listId;
    const selectAll = this.state.selectAll;

    // We must provide a list of ids OR a filter object
    // An empty filter object means that we are enriching all contacts
    const ids = selectAll === true ? null : Object.keys(this.state.selected);
    const filters = selectAll === true ? this.toApiFilters(this.state.filtered) : null;

    this.props.openModal(EnrichConfirmation, {
      count,
      onConfirm: (fields: string[] = []) => {
        this.props.createSegment(listId, ids, filters, fields).then(() => {
          this.unselectAll();
          this.setState({ donorsStale: true });
          this.refReactTable.fireFetchData();
        });
      },
    });
  }

  public getTbodyProps = () => {
    return { 'id': 'main-rt-tbody' };
  }

  public getTrProps = (state, row) => {
    let trProps = {};
    if (row) {
      trProps = { 'can-expand': String(!row.original.hasNoRecentContribution) };
    }
    return trProps;
  }

  public renderName = (row) => {
    const myLoadMore = () => {
      this.onLoadMore(row.original.parentIndex, row.original.numShown + 10);
    };

    return (
      <Name
        name={row.original.name}
        linkedIn={row.original.linkedIn}
        statusLabel={row.original.statusLabel}
        statusIconClass={row.original.statusIconClass}
        source={row.original.source}
        onLoadMore={myLoadMore}
      />
    );
  }

  public renderSubTable = (row) => {
    return (row.original.children.length > 0 &&
      <div className='sub-table'>
        <ReactTable
          data={row.original.children}
          columns={this.getColumns(true, this.props.hasTotalCampaignLifetime, this.props.hasOccupation, this.props.hasEmployer)}
          pageSize={row.original.children.length}
          minRows={0}
          showPagination={false}
          resizable={false}
        />
      </div>
    );
  }

  public renderExpander = (props: any) => {
    const { isExpanded, original } = props;

    if (original.hasNoContribution) {
      return (
        <div className='no-rcpts' title='No contributions found with the provided info' data-toggle='tooltip' data-placement='right'>
          <SVG src={noHistory} className='icon icon-baseline'/>
        </div>
      );
    } else if (original.hasNoRecentContribution) {
      return (
        <div className='no-rcpts' title='No recent contributions found' data-toggle='tooltip' data-placement='right'>
          <SVG src={noRecentHistory} className='icon icon-baseline'/>
        </div>
      );
    } else {
      return (<div className={isExpanded ? 'rt-expander -open' : 'rt-expander'}>&bull;</div>);
    }
  }

  public render() {
    const {
      donorTableData,
      donorTablePages,
      hasTotalCampaignLifetime,
      hasOccupation,
      hasEmployer,
      firstName,
      insights,
      loading,
      displayedRecipients,
    } = this.props;

    return (
      <div className='container-fluid'>
        {insights && insights.canBeRaised > 0 &&
          <InsightsCard firstName={firstName} insights={insights}/>
        }
        <ActionsAndFiltersConnected
          selectedDonorCount={this.getSelectedCount()}
          minFilter={this.state.minFilter}
          maxFilter={this.state.maxFilter}
          employerFilter={this.state.employerFilter}
          donorOccupationFilter={this.state.donorOccupationFilter}
          cityFilter={this.state.cityFilter}
          donorStateFilter={this.state.donorStateFilter}
          recipientSourcesFilter={this.state.recipientSourcesFilter}
          onEnrichClick={this.onEnrichClick}
          onChangeMin={this.onChangeMin}
          onChangeMax={this.onChangeMax}
          onChangeSearch={this.onChangeSearch}
          onSearch={this.updateFilteredState}
          onSelectSource={this.onSelectSource}
          onRemoveSource={this.onRemoveSource}
          onAddAllSources={this.onAddAllSources}
          onResetFilters={this.onResetFilters}
          onChangeEmployer={this.onChangeEmployer}
          onChangeDonorOccupation={this.onChangeDonorOccupation}
          onChangeCity={this.onChangeCity}
          onChangeDonorState={this.onChangeDonorState}
        />
        <ReactTable
          ref={(refReactTable) => {
            this.refReactTable = refReactTable;
          }}
          data={donorTableData}
          pages={donorTablePages}
          columns={this.getColumns(false, hasTotalCampaignLifetime, hasOccupation, hasEmployer)}
          className='table-fr table-fr-results -highlight'
          defaultPageSize={20}
          defaultSortDesc={true}
          resizable={false}
          manual={true}
          getTrProps={this.getTrProps}
          getTbodyProps={this.getTbodyProps}
          onFetchData={this.fetchData}
          onPageChange={this.onPageChange}
          onExpandedChange={this.onExpandedChange}
          filtered={this.state.filtered}
          expanded={displayedRecipients}
          noDataText={loading ? 'Loading...' : 'No donors found'}
          SubComponent={this.renderSubTable}
        />
      </div>
    );
  }

  private onPageChange = () => {
    const mainTbody = document.getElementById('main-rt-tbody');
    if (mainTbody) {
      mainTbody.scrollTop = 0;
    }
  }

  private onExpandedChange = (newExpanded, index) => {
    const thisIndex = index[0];
    const isOpen = newExpanded[thisIndex];
    if (isOpen) {
      const listId = this.props.match.params.listId;
      const donorId = this.props.donorTableData[thisIndex].id;
      if (listId && donorId) {
        const cycles = getCycles();
        this.props.getDonor(listId, donorId, cycles, thisIndex);
      }
    } else {
      this.props.setDisplayedRecipients(thisIndex, false);
    }
  }

  private updateFilteredState = () => {
    const contactInfoFilters: any = [];
    const filtered = [
      { id: 'minCapacityRemaining', value: this.state.minFilter },
      { id: 'maxCapacityRemaining', value: this.state.maxFilter },
      { id: 'search', value: this.state.search },
      { id: 'recipientSources', value: this.state.recipientSourcesFilter },
      { id: 'contactInfo', value: contactInfoFilters },
    ];
    if (this.state.employerFilter) {
      contactInfoFilters.push({
        type: 'employer',
        op: 'starts_with',
        value: this.state.employerFilter,
      });
    }
    if (this.state.donorOccupationFilter) {
      contactInfoFilters.push({
        type: 'occupation',
        subtype: 'class',
        value: this.state.donorOccupationFilter,
      });
    }
    if (this.state.cityFilter) {
      contactInfoFilters.push({
        type: 'address',
        subtype: 'city',
        source: 'campaign',
        op: 'starts_with',
        value: this.state.cityFilter,
      });
    }
    if (this.state.donorStateFilter) {
      contactInfoFilters.push({
        type: 'address',
        subtype: 'state',
        value: this.state.donorStateFilter,
      });
    }
    this.setState({ filtered });
  }

  private onLoadMore = (index, numToShow) => {
    this.props.setDisplayedRecipients(index, numToShow);
  }

  private onChangeMin = (event) => {
    const minFilter = parseInt(event.target.value, 10);
    this.setState({ minFilter });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeMax = (event) => {
    const maxFilter = parseInt(event.target.value, 10);
    this.setState({ maxFilter });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeSearch = (event) => {
    this.setState({ search: event.target.value });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onSelectSource = (event) => {
    const newSource = event.target.value;
    if (newSource) {
      const recipientSourcesFilter = [...new Set([...this.state.recipientSourcesFilter, newSource])];
      this.setState({ recipientSourcesFilter });
      this.unselectAll();
      this.debouncedUpdateFilteredState();
    }
  }

  private onRemoveSource = (source) => {
    const recipientSourcesFilter = this.state.recipientSourcesFilter.filter(s => s !== source);
    this.setState({ recipientSourcesFilter });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private checkStatesOnly = (recipientSourcesFilter) => {
    const diff = this.props.recipientSources.filter(x => !recipientSourcesFilter.includes(x));
    return (diff.length === 1 && diff[0] === 'FEC') ? ['_state_'] : this.state.recipientSourcesFilter;
  }

  private onAddAllSources = () => {
    this.setState({
      recipientSourcesFilter: this.props.recipientSources,
    });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onResetFilters = () => {
    this.setState({
      minFilter: '',
      maxFilter: '',
      recipientSourcesFilter: [],
      employerFilter: '',
      donorOccupationFilter: '',
      cityFilter: '',
      donorStateFilter: '',
    });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeEmployer = (event) => {
    this.setState({ employerFilter: event.target.value });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeDonorOccupation = (event) => {
    this.setState({ donorOccupationFilter: event.target.value });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeCity = (event) => {
    this.setState({ cityFilter: event.target.value });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }

  private onChangeDonorState = (event) => {
    this.setState({ donorStateFilter: event.target.value });
    this.unselectAll();
    this.debouncedUpdateFilteredState();
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    donorTableData: selectors.donorTableDataSelector(state),
    donorTablePages: selectors.donorTablePagesSelector(state),
    donorTableTotal: selectors.donorTableTotalSelector(state),
    hasTotalCampaignLifetime: selectors.hasTotalCampaignLifetimeSelector(state),
    hasEmployer: selectors.hasEmployerSelector(state),
    hasOccupation: selectors.hasOccupationSelector(state),
    displayedRecipients: selectors.displayedRecipientsSelector(state),
    recipientSources: selectors.recipientSourcesSelector(state),
    donorStates: selectors.donorStatesSelector(state),
    firstName: state.auth.user.firstName,
    insights: state.insights,
    loading: state.loading,
  };
  return props;
};

export default connect(mapStateToProps, {
  getDonor,
  getDonors,
  getInsights,
  setDisplayedRecipients,
  openModal,
  createSegment,
})(Results);
