import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { get, post } from 'gateway';
import CampaignStats from 'models/campaign-stats';

const GET_CAMPAIGN_STATS_SUCCESS = 'campaign.get.stats.success';
const POST_CAMPAIGN_STATS_SUCCESS = 'campaign.post.stats.success';

const getCampaignStats = (campaignId) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const r = await get(`campaign/${campaignId}/stats`);
    const campaignStats = (new CampaignStats()).fromJson(r.data);
    dispatch({ type: GET_CAMPAIGN_STATS_SUCCESS, payload: campaignStats });
    dispatch({ type: LOADING_FALSE });
    return campaignStats;
  } catch (e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

const refreshCampaignStats = (campaignId) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const r = await post(`campaign/${campaignId}/stats`);
    dispatch({ type: POST_CAMPAIGN_STATS_SUCCESS });
    dispatch(getCampaignStats(campaignId));
  } catch (e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

export {
  GET_CAMPAIGN_STATS_SUCCESS,
  getCampaignStats,
  refreshCampaignStats,
};
