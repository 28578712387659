class Insights {
  public over500: number;
  public canBeRaised: number;

  constructor(fields: any = {}) {
    Object.keys(fields).forEach(f => this[f] = fields[f]);
  }

  public fromJson(data: any) {
    this.over500 = data.over_500;
    this.canBeRaised = data.can_be_raised;
    return this;
  }

}

export default Insights;
