import Base from 'models/base';

interface ICampaignStatsYears {
  [year: string]: ICampaignStatsYear;
}

interface ICampaignStatsYear {
  months: ICampaignStatsMonths;
}

interface ICampaignStatsMonths {
  [month: string]: ICampaignStatsMonth;
}

interface ICampaignStatsMonth {
  donors: number;
  enriched: number;
  enrichedAttempted: number;
}

interface ICampaignStatsTotals {
  donors: number;
  enriched: number;
  enrichedAttempted: number;
}

class CampaignStats extends Base {
  public years: ICampaignStatsYears;
  public totals: ICampaignStatsTotals;
  public lastUpdated: Date;

  protected fromJsonInternal(fields: any) {
    this.years = fields.years;
    this.totals = fields.totals;
    this.lastUpdated = new Date(fields.meta.lastUpdated);
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.id === other.id
    );
  }
}

export default CampaignStats;
