const ERROR = 'error';
const ERROR_CLEAR = 'error.clear';

const setError = (message: string, fatal: boolean = false) => async (dispatch) => {
  if (!fatal) {
    const clear = () => dispatch({ type: ERROR_CLEAR });
    setTimeout(clear, 4000);
  }

  dispatch({
    type: ERROR,
    payload: { message },
  });
};

const clearError = () => async (dispatch) => {
  dispatch({ type: ERROR_CLEAR });
};

export {
  ERROR,
  ERROR_CLEAR,
  setError,
  clearError,
};
