import { dismissModal } from 'actions/modals';
import { magicWandImg } from 'images';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';


const EnrichmentField = (props: any) => {
  const { value, label, onChange } = props;
  const id = `enrich-${value}`;
  return (
    <div className='form-check'>
      <input className='form-check-input' type='checkbox' value={value} id={id} onChange={onChange} />
      <label className='form-check-label pl-1' htmlFor={id}>{label}</label>
    </div>
  );
};

class EnrichConfirmation extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { checkedFields: [] };
  }

  public onChangeSelect = (event) => {
    let newCheckedFields;
    if (event.target.checked) {
      newCheckedFields = [...this.state.checkedFields, event.target.value];
    } else {
      newCheckedFields = this.state.checkedFields.filter(v => v !== event.target.value);
    }
    this.setState({ checkedFields: newCheckedFields });
  }

  public onConfirm = () => {
    this.props.dismiss();
    this.props.onConfirm(this.state.checkedFields);
  }

  public render() {
    const {
      dismiss,
      count,
    } = this.props;

    return (
      <div className='enrichment-modal modal-content p-3'>
        <button type='button' className='btn btn-close' onClick={dismiss}>&times;</button>
        <SVG src={magicWandImg} className='icon icon-large icon-baseline icon-primary'/>
        <div className='modal-body my-1'>
          <p className='text-center h5 mb-4'>
            You're about to enrich {count}&nbsp;contact{count === 1 ? '' : 's'}!
          </p>
          <p className='text-center checklist-header mb-3'>
            Select the information <wbr/>
            you'd like to enrich:
          </p>
          <div className='mx-auto d-table'>
            <EnrichmentField onChange={this.onChangeSelect} value='email' label='Email address' />
            <EnrichmentField onChange={this.onChangeSelect} value='phone' label='Phone number' />
            <EnrichmentField onChange={this.onChangeSelect} value='employer' label='Employment information' />
            <EnrichmentField onChange={this.onChangeSelect} value='linkedin' label='LinkedIn profile' />
          </div>
        </div>
        <p className='smaller-text mb-3'>
          Note: We'll return all other information we can find for a given person as long as we also
          find information for at least one of the types selected above.
        </p>
        <div className='d-flex justify-content-center'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onConfirm}
            disabled={this.state.checkedFields.length === 0}
          >Enrich Contacts</button>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  dismiss: dismissModal,
})(EnrichConfirmation);
