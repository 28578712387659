import Base from 'models/base';

class Job extends Base {
  public status: string;
  public createdAt: Date;
  public updatedAt: Date;
  public listId: string;
  public userId: string;
  public runPdl: boolean;
  public oneNation?: boolean;

  public displayStatus(): string {
    if (this.runPdl && this.status !== 'complete') {
      return 'Enriching';
    }

    switch (this.status) {
      case 'queued':
        return 'Not started';
      case 'validating':
      case 'normalizing':
        return 'Validating';
      case 'complete':
        return 'Complete';
      case 'cancelled':
        return 'Cancelled';
      default:
        return 'Processing';
    }
  }

  protected fromJsonInternal(data: any) {
    this.status = data.status;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.listId = data.listId;
    this.userId = data.userId;
    this.runPdl = data.runPdl;
    this.oneNation = data.oneNation;
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.id === other.id
    );
  }
}

export default Job;
