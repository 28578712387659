const routes = {
  account: '/account',
  faq: '/faq',
  home: '/',
  lists: '/lists',
  polaris: '/polaris',
  usageSummary: '/usage-summary',
  usageSummaryAlt: '/campaign/:campaignId/usage-summary',
  results: '/list/:listId/results',
  submission: {
    upload: '/campaign/:campaignId/upload',
    analyze: '/campaign/:campaignId/upload-complete',
    analyzing: '/campaign/:campaignId/list/:listId/analyzing',
    error: '/campaign/:campaignId/list/:listId/error',
  },
  login: {
    admin: '/login/admin',
    create: '/login/register',
    requestReset: '/login/request-reset',
    reset: '/login/reset',
    index: '/login',
  },
  uploadInfo: '/upload-info',
  donorLookup: '/lookup',
  lookupHistory: '/lookup-history',
  error: '/error',
};

export default routes;
