import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { get, post } from 'gateway';

const LOOKUP_DONOR_SUCCESS = 'lookup.donor.success';
const LOOKUP_HISTORY_SUCCESS = 'lookup.history.success';

const lookupDonor = (firstName, lastName, zipcode, city, state) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const data = {
      firstName,
      lastName,
      zipcode,
      city,
      state,
    };
    const r = await post('lookup/donor', data);
    const result = r.data;
    dispatch({ type: LOOKUP_DONOR_SUCCESS, payload: result });
    dispatch({ type: LOADING_FALSE });
    return result;
  } catch (e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

const getLookupHistory = () => async (dispatch) => {
  dispatch({ type: LOADING_TRUE });
  try {
    const r = await get('lookup/history');
    const result = r.data;
    dispatch({ type: LOOKUP_HISTORY_SUCCESS, payload: result });
    dispatch({ type: LOADING_FALSE });
    return result;
  } catch (e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

const pingLookupService = () => async (dispatch) => {
  await get('lookup/donor');
};

export {
  LOOKUP_DONOR_SUCCESS,
  LOOKUP_HISTORY_SUCCESS,
  getLookupHistory,
  lookupDonor,
  pingLookupService,
};
