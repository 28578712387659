import * as queryString from 'query-string';
import { createSelector } from 'reselect';
import routes from 'routes';

const donorLookupSelector = (state: any) => state.donorLookup;

const donorLookupTableDataSelector = createSelector(donorLookupSelector, (donorLookup: any) => {
  let result: any = null;

  if (donorLookup && donorLookup.donations) {
    result = donorLookup.donations.map(contrib => {
      const donorLocation = `${contrib.donor.city.toLowerCase()}, ${contrib.donor.state}`;
      const recipientParty = `(${contrib.recipient.party.slice(0, 1)})`;
      const recipientName = `${contrib.recipient.name.toLowerCase()} ${recipientParty !== '(U)' ? (recipientParty) : ''}`;
      return {
        donorName: contrib.donor.name,
        donorEmployer: contrib.donor.employer.toLowerCase(),
        donorOccupation: contrib.donor.occupation.toLowerCase(),
        donorLocation,
        donorZip: contrib.donor.zipcode.slice(0, 5),
        recipientName,
        recipientParty,
        source: contrib.source,
        date: contrib.date,
        amount: contrib.amount,
      };
    });
  }

  return result;
});

const donorLookupStatsSelector = createSelector(donorLookupSelector, (donorLookup: any) => {
  let stats: any = {
    cycles: {},
    donor: {},
  };
  if (donorLookup && Object.keys(donorLookup.stats).length > 0) {
    stats = donorLookup.stats;
  }
  return stats;
});

const donorLookupHistorySelector = (state: any) => state.donorLookupHistory;

const donorLookupHistoryTableDataSelector = createSelector(donorLookupHistorySelector, (donorLookupHistory: any) => {
  let result: any = null;
  if (donorLookupHistory && donorLookupHistory.lookups) {
    result = donorLookupHistory.lookups.map(lookup => {
      const donorName = lookup.lastName ? `${lookup.firstName} ${lookup.lastName}` : lookup.firstName;
      const donorState = (lookup.state || '').toUpperCase();
      const donorGeo = lookup.zipcode ? lookup.zipcode : `${lookup.city}, ${donorState}`;
      const lookupQuery = queryString.stringify({
        first: lookup.firstName,
        last: lookup.lastName,
        zipcode: lookup.zipcode,
        city: lookup.city,
        state: donorState,
      });
      const lookupLink = `${routes.donorLookup}?${lookupQuery}`;
      return {
        donorName,
        donorGeo,
        lastLookup: lookup.lastLookup,
        lookupLink,
      };
    });
  }
  return result;
});

export {
  donorLookupHistoryTableDataSelector,
  donorLookupTableDataSelector,
  donorLookupStatsSelector,
};
