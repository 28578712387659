abstract class Base {

  public id: string;

  constructor(fields: any = {}) {
    Object.keys(fields).forEach(f => this[f] = fields[f]);
  }

  public fromJson(fields: any) {
    this.id = fields['id'];
    return this.fromJsonInternal(fields);
  }

  public toJson(): any {
    return {
      id: this.id,
    };
  }

  public equals(other: any): boolean {
    return (this.id === other.id) && this.equalsInternal(other);
  }

  protected abstract fromJsonInternal(fields: any): any;
  protected abstract equalsInternal(other: any): boolean;

}

export default Base;
