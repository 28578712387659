import { SET_SHOW_ALL_LISTS } from 'actions/lists';
import { CLEAR_DISPLAYED_RECIPIENTS, SET_DISPLAYED_RECIPIENTS } from 'actions/results';

interface IDisplayedRecipients {
  [index: number]: number | boolean;
}

interface IViewSettings {
  showAllLists: boolean;
  displayedRecipients: IDisplayedRecipients;
}

const reducer = (state: IViewSettings | null = null, action) => {
  switch (action.type) {
    case SET_SHOW_ALL_LISTS:
      return {
        ...state,
        showAllLists: action.value,
      };
    case SET_DISPLAYED_RECIPIENTS:
      const newDisplayedRecipients = { ...state && state.displayedRecipients };
      newDisplayedRecipients[action.index] = action.value;

      return {
        ...state,
        displayedRecipients: newDisplayedRecipients,
      };
    case CLEAR_DISPLAYED_RECIPIENTS:
      return {
        ...state,
        displayedRecipients: {},
      };
  }
  return state;
};

export default reducer;
