import { getLookupHistory } from 'actions/donors';
import DateTime from 'components/ui/DateTime';
import { lookupIcon } from 'images';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import routes from 'routes';
import { donorLookupHistoryTableDataSelector } from 'selectors/donor-lookup';


class LookupHistory extends React.Component<any, any> {

  public componentDidMount() {
    this.props.getLookupHistory();
  }

  public render() {
    const {
      donorLookupHistoryTableData,
      firstName,
      campaignId,
      loading,
    } = this.props;
    const pageSize = 20;
    const uploadUrl = routes.submission.upload.replace(':campaignId', campaignId);
    const hasLookups = donorLookupHistoryTableData && donorLookupHistoryTableData.length > 0;

    return (
      <div className='container-fluid'>
        <div className='d-flex justify-content-center mb-4 mt-sm-3'>
          <div className='d-flex justify-content-around col-sm-8 col-lg-6'>
            <NavLink to={routes.lists} className='landing-nav'>Uploaded Lists</NavLink>
            <NavLink to={routes.lookupHistory} className='landing-nav'>Recent Lookups</NavLink>
          </div>
        </div>

        {hasLookups ? (
          <>
            <div className='card mb-4'>
              <div className='card-body d-flex flex-column-reverse flex-sm-row'>
                <div className='mr-sm-4'>
                  Hey {firstName}, here is a list of people whose donor history you've looked up recently.<br/>
                  To repeat a search, click the Results link.
                </div>
                <Link to={routes.donorLookup} className='btn btn-primary text-uppercase d-block mb-2 my-sm-auto ml-sm-auto'>
                  Look up a supporter
                </Link>
              </div>
            </div>
            <ReactTable
              data={donorLookupHistoryTableData}
              className='table-fr table-fr-lookups'
              columns={this.getColumns()}
              minRows={0}
              pageSize={pageSize}
              showPagination={donorLookupHistoryTableData.length > pageSize}
              noDataComponent={ () => null }
            />
          </>
        ) : (
          <>{!loading &&
            <div className='card card-medium mx-auto'>
              <div className='card-body center px-4 px-lg-5 py-5'>
                <img height={128} className='mb-4' src={lookupIcon}/>
                <p className='card-text text-nowrap mb-4'>
                  There's nothing here yet! <br/>
                  Get started by looking up an <wbr/>
                  individual's <wbr/>donation history.
                </p>
                <Link to={routes.donorLookup} className='btn btn-lg btn-primary text-uppercase d-block col-sm-8 col-md-6 mx-auto'>
                  Look up a supporter
                </Link>
              </div>
            </div>
          }</>
        )
        }
      </div>
    );
  }

  private getColumns = () => {
    return [
      {
        Header: 'Name',
        accessor: 'donorName',
        className: 'd-flex justify-content-center',
        sortable: false,
      }, {
        Header: 'Location',
        accessor: 'donorGeo',
        className: 'd-flex justify-content-center text-capitalize',
        sortable: false,
      }, {
        Header: 'Lookup Date',
        accessor: 'lastLookup',
        headerClassName: 'd-none d-sm-flex justify-content-center',
        className: 'd-none d-sm-flex justify-content-center',
        sortable: false,
        Cell: row => <DateTime value={row.value} format='ll'/>,
      }, {
        Header: 'Results',
        accessor: 'lookupLink',
        className: 'd-flex justify-content-center',
        sortable: false,
        minWidth: 40,
        Cell: row => <Link to={row.value}><span className='d-none d-md-inline'>See </span>Results</Link>,
      },
    ];
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    campaignId: state.auth.user.campaign.id,
    donorLookupHistoryTableData: donorLookupHistoryTableDataSelector(state),
    firstName: state.auth.user.firstName,
    loading: state.loading,
  };
  return props;
};

export default connect(mapStateToProps, {
  getLookupHistory,
})(LookupHistory);
