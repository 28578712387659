import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';

const reducer = (state: boolean = false, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return true;
    case LOADING_FALSE:
      return false;
  }

  return state;
};

export default reducer;
