import { setError } from 'actions/error';
import { LOADING_FALSE, LOADING_TRUE } from 'actions/loader';
import { post } from 'gateway';
import UploadToken from 'models/upload-token';

const CREATE_UPLOAD_TOKEN_SUCCESS = 'upload-token.get.success';

const createUploadToken = (campaignId) => async (dispatch) => {

  dispatch({ type: LOADING_TRUE });
  try {
    const r = await post('submission/upload-tokens', {
      campaignId,
    });
    const uploadToken = (new UploadToken()).fromJson(r.data);
    dispatch({ type: CREATE_UPLOAD_TOKEN_SUCCESS, payload: uploadToken });
    dispatch({ type: LOADING_FALSE });
    return uploadToken;
  } catch(e) {
    dispatch(setError(e.message, true));
  }

  dispatch({ type: LOADING_FALSE });
  return null;
};

export {
  CREATE_UPLOAD_TOKEN_SUCCESS,
  createUploadToken,
};
