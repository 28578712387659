import Base from 'models/base';

class User extends Base {

  public email: string;
  public firstName: string;
  public lastName: string;
  public campaign: any;
  public token: string;

  public fullName(): string {
    return [this.firstName, this.lastName].filter(n => n).join(' ').trim();
  }

  protected fromJsonInternal(fields: any) {
    this.email = fields.email;
    this.firstName = fields.first_name || fields.firstName;
    this.lastName = fields.last_name || fields.lastName;
    this.campaign = fields.campaign;
    this.token = fields.token;
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.email === other.email &&
      this.firstName === other.firstName &&
      this.lastName === other.lastName &&
      this.campaign === other.campaign &&
      this.token === other.token
    );
  }

}

export default User;
