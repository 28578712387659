import Base from 'models/base';

class UploadToken extends Base {

  public url: string;
  public policy: string;
  public signature: string;
  public algorithm: string;
  public credential: string;
  public date: string;
  public contentType: string;
  public acl:string;
  public fileKey:string;
  public successActionRedirect: string;

  protected fromJsonInternal(data: any) {
    const { fields, url } = data;
    this.url = url;
    this.policy = fields.policy;
    this.signature = fields['x-amz-signature'];
    this.algorithm = fields['x-amz-algorithm'];
    this.credential = fields['x-amz-credential'];
    this.date = fields['x-amz-date'];
    this.contentType = fields['Content-Type'];
    this.acl = fields.acl;
    this.fileKey = fields.key;
    this.successActionRedirect = fields.success_action_redirect;
    return this;
  }

  protected equalsInternal(other: any): boolean {
    return (
      this.policy === other.policy &&
      this.signature === other.signature
    );
  }

}

export default UploadToken;
