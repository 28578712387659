import CampaignStats  from 'models/campaign-stats';
import * as moment from 'moment';
import { createSelector } from 'reselect';

const campaignStatsSelector = (state: any) => state.campaignStats;

const campaignStatsTableDataSelector = createSelector(campaignStatsSelector, (campaignStats: CampaignStats) => {
  const result: any[] = [];

  if (campaignStats) {
    Object.keys(campaignStats.years).map(year => {
      Object.keys(campaignStats.years[year].months).map(month => {
        const monthStats = campaignStats.years[year].months[month];
        const jsMonth = parseInt(month, 10) - 1; // JS months are 0-based
        result.push({
          period: moment([year, jsMonth]).format('MMMM YYYY'),
          donors: `${monthStats.donors} unique`,
          enriched: `${monthStats.enriched} enriched`,
          timestamp: moment([year, jsMonth]).unix(),
        });
      });
    });
  }

  // Order by date (most recent first)
  result.sort((a, b) => b.timestamp - a.timestamp);

  return result;
});

const currentMonthStatsDataSelector = createSelector(campaignStatsSelector, (campaignStats: CampaignStats) => {
  const now = moment();
  const result = {
    period: now.format('MMMM YYYY'),
    donors: 0,
    enriched: 0,
  };

  const year = now.year();
  const month = now.month() + 1;

  if (campaignStats && campaignStats.years[year] && campaignStats.years[year].months[month]) {
    const currentMonthStats = campaignStats.years[year].months[month];
    result.donors = currentMonthStats.donors;
    result.enriched = currentMonthStats.enriched;
  }

  return result;
});

export {
  campaignStatsTableDataSelector,
  currentMonthStatsDataSelector,
};
