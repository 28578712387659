import { getLists, setShowAllListsFilter } from 'actions/lists';
import { openModal } from 'actions/modals';
import FileDownload from 'components/modals/FileDownload';
import DateTime from 'components/ui/DateTime';
import { lookupIcon } from 'images';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import routes from 'routes';
import { listTableDataSelector } from 'selectors/lists';

const DEFAULT_PAGE_SIZE = 10;

const OutputFiles = (props: any) => {
  const { list, onDownloadClick } = props;

  let result: any = null;
  if (list && list.files && list.files.length) {
    result = (
      <button className='btn btn-sm btn-primary' onClick={onDownloadClick.bind(this, list.files)}>
        Select files
      </button>
    );
  }

  return result;
};

const ResultsLink = (props: any) => {
  const { list } = props;
  let result: any = null;

  switch (list.lastJobStatus) {
    case 'Complete':
    case 'Enriching':
      const resultsUrl = routes.results.replace(':listId', list.id);
      result = <Link to={resultsUrl}><span className='d-none d-md-inline'>See </span>Results</Link>;
      break;
    case 'Cancelled':
      result = ('Cancelled');
      break;
    default:
      result = ('Pending');
  }
  return result;
};

class AllResults extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      hasLists: false,
    };
  }

  public componentDidMount() {
    const { campaignId, listTableData } = this.props;
    if (campaignId) {
      this.props.getLists(campaignId).then((lists) => {
        if (lists && Object.keys(lists).length > 0) {
          this.setState({ hasLists: true });
        }
      });
    }
  }

  public onDownloadClick = (files) => {
    this.props.openModal(FileDownload, { files });
  }

  public getColumns = () => {
    return [
      {
        Header: 'Uploaded File',
        accessor: 'filename',
        className: 'd-flex align-items-center px-3',
      }, {
        Header: 'Upload Date',
        accessor: 'createdAt',
        headerClassName: 'd-none d-sm-block',
        className: 'd-none d-sm-flex align-items-center justify-content-center px-3',
        maxWidth: 150,
        Cell: row => <DateTime value={row.value} format='ll'/>,
      }, {
        Header: 'Processing Status',
        accessor: 'lastJobStatus',
        className: 'd-flex align-items-center justify-content-center px-3',
        maxWidth: 150,
      }, {
        Header: 'Results',
        className: 'd-flex align-items-center justify-content-center px-3',
        maxWidth: 150,
        Cell: row => <ResultsLink list={row.original}/>,
      }, {
        Header: 'Result CSV Files',
        headerClassName: 'd-none d-sm-block',
        className: 'd-none d-sm-block text-center',
        maxWidth: 150,
        Cell: row => <OutputFiles list={row.original} onDownloadClick={this.onDownloadClick} />,
      }, {
        Header: 'User',
        accessor: 'user',
        headerClassName: 'd-none d-md-block',
        className: 'd-none d-md-flex align-items-center justify-content-center px-3',
        maxWidth: 150,
      },
    ];
  }

  public render() {
    const { loading, listTableData, firstName, campaignId, showAllLists } = this.props;
    const uploadUrl = routes.submission.upload.replace(':campaignId', campaignId);
    const userModeClass = campaignId ? '' : ' disabled';

    return (
      <div className='container-fluid'>
        <div className='d-flex justify-content-center mb-4 mt-sm-3'>
          <div className='d-flex justify-content-around col-sm-8 col-lg-6'>
            <NavLink to={routes.lists} className='landing-nav'>Uploaded Lists</NavLink>
            <NavLink to={routes.lookupHistory} className='landing-nav'>Recent Lookups</NavLink>
          </div>
        </div>

        {this.state.hasLists ? (
          <>
            <div className='card mb-4'>
              <div className='card-body d-flex flex-column-reverse flex-sm-row'>
                <div className='mr-sm-4'>
                  Hey {firstName}, here is a list of files {showAllLists ? "your team's" : "you've"} uploaded and their processing status.<br/>
                  To view results, click the Results link.
                </div>
                <Link to={uploadUrl} className='btn btn-primary text-uppercase d-block mb-2 my-sm-auto ml-sm-auto'>
                  Upload a new list
                </Link>
              </div>
            </div>
            <div className='form-group'>
              <div className='custom-control custom-switch'>
                <input type='checkbox' className='custom-control-input' checked={showAllLists} id='showAllCheck'
                       onChange={this.toggleShowAll} />
                  <label className='custom-control-label' htmlFor='showAllCheck'>Show lists from all users</label>
              </div>
            </div>

            <ReactTable
              data={listTableData}
              className='table-fr table-fr-home mb-3'
              columns={this.getColumns()}
              minRows={3}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              noDataText={loading ? 'Loading...' : 'No uploads yet'}
              showPagination={listTableData.length > DEFAULT_PAGE_SIZE}
            />
          </>
        ) : (
          <>{!loading &&
            <div className='card card-medium mx-auto'>
              <div className='card-body center px-4 px-lg-5 py-5'>
                <img height={128} className='mb-4' src={lookupIcon}/>
                <p className='card-text text-nowrap mb-4'>
                  There's nothing here yet! <br/>
                  Get started by uploading a <wbr/>
                  fundraising/volunteer list.
                </p>
                <Link to={uploadUrl} className={'btn btn-lg btn-primary text-uppercase d-block col-sm-8 col-md-6 mx-auto' + userModeClass}>
                  Upload a new list
                </Link>
              </div>
            </div>
          }</>
        )
        }
      </div>
    );
  }

  private toggleShowAll = (event) => {
    const checked = event.target.checked;
    this.props.setShowAllListsFilter(checked);
  }
}

const mapStateToProps = (state: any) => {
  const props: any = {
    campaignId: state.auth.user.campaign.id,
    listTableData: listTableDataSelector(state),
    showAllLists: state.viewSettings ? state.viewSettings.showAllLists : false,
    firstName: state.auth.user.firstName,
    loading: state.loading,
  };
  return props;
};

export default connect(mapStateToProps, {
  getLists,
  openModal,
  setShowAllListsFilter,
})(AllResults);
