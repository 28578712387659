const analyzingImg = require('assets/images/analyzing-icon.png');
const createImg = require('assets/images/create-account-icon.png');
const downloadIcon = require('assets/images/download-icon.png');
const enrichStatusImg = require('assets/images/enrich-status.svg');
const failImg = require('assets/images/fail-icon.png');
const lookupIcon = require('assets/images/lookup-icon.png');
const magicWandImg = require('assets/images/magic-wand.svg');
const moneyIcon = require('assets/images/money-icon.png');
const noHistory = require('assets/images/no-history.svg');
const noRecentHistory = require('assets/images/no-recent-history.svg');
const refreshIcon = require('assets/images/refresh.svg');
const passwordResetImg = require('assets/images/password-reset-icon.png');
const quickstartGuideThumbnail = require('assets/images/quickstart-guide-thumbnail.png');
const signInImg = require('assets/images/sign-in-icon.png');
const successImg = require('assets/images/success-icon.png');
const targetIcon = require('assets/images/target-icon.png');
const tfcLogo = require('assets/images/tfc-logo-gray.png');
const welcomeImg = require('assets/images/welcome-icon.png');


export {
  analyzingImg,
  createImg,
  downloadIcon,
  enrichStatusImg,
  failImg,
  lookupIcon,
  magicWandImg,
  moneyIcon,
  noHistory,
  noRecentHistory,
  passwordResetImg,
  quickstartGuideThumbnail,
  refreshIcon,
  signInImg,
  successImg,
  targetIcon,
  tfcLogo,
  welcomeImg,
};
