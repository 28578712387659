import { GET_DONOR_SUCCESS, GET_DONORS_SUCCESS } from 'actions/results';

const updateObjectInArray = (array, action) => array.map((item, index) => {
  if (index !== action.index) {
    return item;
  }
  return {
    ...item,
    ...action.item,
  };
});

const reducer = (state: any | null = null, action) => {
  switch (action.type) {
    case GET_DONOR_SUCCESS:
      let updatedItems = [action.payload];
      if (state) {
        const index = state.items.findIndex((donor) => donor.id === action.payload.id);
        if (index >= 0) {
          updatedItems = updateObjectInArray(state.items, { index, item: action.payload });
        } else {
          updatedItems = state.items;
        }
      }

      return {
        ...state,
        meta: {
          ...state.meta,
        },
        items: updatedItems,
      };
    case GET_DONORS_SUCCESS:
      return action.payload;
  }

  return state;
};

export default reducer;
